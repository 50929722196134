import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

import { Company, Companies } from 'src/app/models/company';
import { ILicense } from 'src/app/models/ILicense';
import { Permissions, UserProfile } from 'src/app/models/user';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { LicensesService } from 'src/app/services/licenses.service';
import { SoftwaresService } from 'src/app/services/softwares.service';
import { MachineryService } from 'src/app/services/machinery.service';

import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';

import { LicenseAttachDialogComponent } from './license-attach-dialog/license-attach-dialog.component';

import * as _ from 'lodash';

import * as FileSaver from 'file-saver';
import { ISoftware } from 'src/app/models/ISoftware';
import { ILicensesGroupedOrder } from 'src/app/models/ISWLicenseApi';
import { IDownloadUrlResponse } from 'src/app/models/ISWSoftwareApi';

@Component({
    selector: 'app-licenses-page',
    templateUrl: './licenses-page.component.html',
    styleUrls: ['./licenses-page.component.scss']
})
export class LicensesPageComponent implements OnInit {

    licenses: Array<ILicense>;
    grouped: Array<ILicensesGroupedOrder>;
    firmwares: Array<ISoftware>;
    showOrders: any;
    showFirmwares: any;

    showLicenses: boolean;

    searchCompany: string;
    searchPartnerId: string;
    searchSerialNumber: string;
    searchDeviceId: string;
    searchBundleId: string;
    searchProductNumber: string;
    searchOrderNumber: string;
    searchNotUsed: boolean;
    searchNotActive: boolean;
    searchSize: number;
    searchParams: Array<any>;
    // Customer/partner text search
    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();

    allItemsChecked: boolean;
    anyItemSelected: boolean;
    selectedItems: object;
    orderPackageSequenceNumber: number;

    offlineDownloadAllowed: boolean;
    reattachLicenseAllowed: boolean;

    includeForcedVRD: boolean;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * User profile
     */
    user: UserProfile;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    loadingOrders: boolean;
    loadingLicenses: boolean;
    loadingFirmwares: boolean;
    loadingAttachment: boolean;
    loadingDownload: boolean;

    showKoyTools: boolean;

    public _ = _;

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public authService: AuthService,
        public stateService: StateService,
        private licensesService: LicensesService,
        private softwaresService: SoftwaresService,
        private machineryService: MachineryService
    ) {
        this.loadingOrders = false;
        this.loadingLicenses = false;
        this.loadingFirmwares = false;
        this.loadingAttachment = false;
        this.loadingDownload = false;
        this.showKoyTools = false;
        this.includeForcedVRD = false;
        this.companies = Companies;
        this.licenses = [];
        this.grouped = [];
        this.firmwares = [];

        this.showOrders = [];
        this.showFirmwares = [];
        this.showLicenses = false;

        this.allItemsChecked = false;
        this.anyItemSelected = false;
        this.selectedItems = {};
        this.resetSearchParams();
    }

    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.file = '';

            this.user = this.authService.userProfile;
            this.permissions = this.user.permissions;
            // Set default search to own company and partnerId
            this.searchCompany = this.user.company;
            this.searchPartnerId = this.user.partnerId;
            this.search();
            this.searchFirmwares();
        });
    }

    loadMore() {
        // this.searchFrom = this.searchFrom + this.searchSize;
        this.searchSize = 70;
        this.checkSearchParams();
        this.searchLicenses();
    }

    search() {
        this.checkSearchParams();
        // Licenses search replaced with getLicensesFromGroups()
        // this.loadingLicenses = true;
        // this.licensesService.getLicenses(this.searchParams).subscribe((licenses: Array<ILicense>) => {
        //     this.licenses = licenses || [];
        //     this.resetSelectedItems();
        //     this.loadingLicenses = false;
        // });
        this.searchLicenses();
    }

    searchWithSerial() {
        const serial = this.searchSerialNumber;
        this.resetSearchParams();
        this.searchSerialNumber = serial;
        this.checkSearchParams();
        this.searchLicenses(this.showAndSelectAllLicenses);
    }

    showAndSelectAllLicenses() {
        if (this.licenses.length > 0) {
            this.showLicenses = true;
            this.allItemsChecked = true;
            this.selectAllItems(this.allItemsChecked);
        }
    }

    searchLicenses(cb = null) {
        this.loadingOrders = true;
        this.licensesService.getLicensesGrouped(this.searchParams).subscribe((grouped: Array<ILicensesGroupedOrder>) => {
            this.grouped = grouped || [];
            this.loadingOrders = false;
            this.resetSelectedItems();
            this.getLicensesFromGroups();
            if (cb) {
                cb.call(this);
            }
        });
    }

    searchFirmwares() {
        this.loadingFirmwares = true;
        const searchSoftwaresParams = [
            { key: 'softwareType', value: 'FIRMWARE_PACK' },
            { key: 'company', value: this.searchCompany },
            { key: 'partnerId', value: this.searchPartnerId }
        ];
        this.softwaresService.getSoftwares(searchSoftwaresParams).subscribe((softwares: Array<ISoftware>) => {

            this.firmwares = softwares;
            this.loadingFirmwares = false;
        });
    }


    getLicensesFromGroups() {
        this.licenses = _.flatMap(this.grouped, (orders) => {
            return _.flatMap(orders.bundleGroups, (bundleGroup) => {
                return _.flatMap(bundleGroup.bundles, (bundle) => {
                    return bundle.licenses;
                });
            });
        });
    }

    searchCustomers() {
        if (this.searchPartner.length > 3) {
            clearTimeout(this.searchCustomerTimer);
            this.searchCustomerTimer = setTimeout(() => {
                const params = [
                    { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' }
                ];
                this.machineryService.searchCustomers(params).subscribe((results) => {
                    this.customersList = results as Array<any>;
                }, (error) => {
                    console.log('Error, searchCustomers', error);
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changeCustomer(customer) {
        if (customer && customer.value && customer.value.name && customer.value.custId && customer.value.company) {
            console.log('changeCustomer', customer.value);
            this.searchPartner = customer.value.name;
            this.searchPartnerId = customer.value.custId;
            this.searchCompany = customer.value.company;
            this.search();
        }
    }

    customSortByNumber(a, b) {
        return (Number(a.match(/(\d+)/g)[0]) - Number((b.match(/(\d+)/g)[0])));
    }

    checkSearchParams() {
        this.searchParams = [];
        this.searchCompany = this.searchCompany.trim();
        this.searchPartnerId = this.searchPartnerId.trim();
        this.searchSerialNumber = this.searchSerialNumber.trim();
        this.searchDeviceId = this.searchDeviceId.trim();
        this.searchBundleId = this.searchBundleId.trim();
        this.searchProductNumber = this.searchProductNumber.trim();
        this.searchOrderNumber = this.searchOrderNumber.trim();
        if (this.searchCompany !== '') {
            this.searchParams.push({ key: 'company', value: this.searchCompany });
        }
        if (this.searchPartnerId !== '') {
            this.searchParams.push({ key: 'partnerId', value: this.searchPartnerId });
        }
        if (this.searchSerialNumber !== '') {
            this.searchParams.push({ key: 'serialNumber', value: this.searchSerialNumber });
        }
        if (this.searchDeviceId !== '') {
            this.searchParams.push({ key: 'deviceId', value: this.searchDeviceId });
        }
        if (this.searchBundleId !== '') {
            this.searchParams.push({ key: 'bundleId', value: this.searchBundleId });
        }
        if (this.searchProductNumber !== '') {
            this.searchParams.push({ key: 'productNumber', value: this.searchProductNumber });
        }
        if (this.searchOrderNumber !== '') {
            this.searchParams.push({ key: 'orderNumber', value: this.searchOrderNumber });
        }
        if (this.searchNotUsed === true) {
            this.searchParams.push({ key: 'notUsed', value: true });
        }
        if (this.searchNotActive === true) {
            this.searchParams.push({ key: 'notActive', value: true });
        }
        if (this.searchSize) {
            this.searchParams.push({ key: 'size', value: this.searchSize });
        }
    }

    filter() {

    }

    resetSearchParams() {
        this.searchPartner = '';
        this.searchCompany = this.user ? this.user.company : '';
        this.searchPartnerId = this.user ? this.user.partnerId : '';
        this.searchSerialNumber = '';
        this.searchDeviceId = '';
        this.searchBundleId = '';
        this.searchProductNumber = '';
        this.searchOrderNumber = '';
        this.searchNotUsed = false;
        this.searchNotActive = false;
        this.searchSize = 20;
        this.searchParams = [];
    }

    selectBundleLicenses(orderId, bundleId, orderPackageSequenceNumber = 0) {
        const licenses = _.filter(this.licenses, { orderNumber: orderId, data: { bundleId } });
        const selectItems = {};
        for (const license of licenses) {
            selectItems[license.id] = true;
        }
        const currentlySelectedItems = _.pickBy(this.selectedItems, (value, key) => value === true);
        // get selected orders id, you can only select licenses from same order
        const selectedOrderId = Object.keys(currentlySelectedItems)[0]?.split('-')[1]
        // reset different order selections, only bundles under same order can be selected
        if (selectedOrderId !== orderId) {
            this.resetSelectedItems();
        }

        // get selectItemIds
        for (let selectId of Object.keys(selectItems)) {
            // if the item was already selected, remove selection
            if (this.selectedItems[selectId]) {
                this.selectedItems[selectId] = false
                selectItems[selectId] = false;
            }
        }

        this.selectedItems = _.merge(this.selectedItems, selectItems);

        const selectedLicenses = this.licenses.filter(license => {
            return this.selectedItems[license.id];
        })

        // get first license to compare other licenses
        const selectedLicense = selectedLicenses[0];
        for (let lic of this.licenses) {
            // Disable licenses with different serialnumber in same order
            if (lic.orderNumber === selectedLicense?.orderNumber) {
                if (lic.serialNumber !== selectedLicense?.serialNumber) {
                    lic['disabledForSelection'] = true;
                    continue;
                }
            }
            lic['disabledForSelection'] = false;
        }

        this.orderPackageSequenceNumber = orderPackageSequenceNumber;
        this.checkSelectedItems();
    }

    /**
     * Reset selections (on search)
     */
    resetSelectedItems() {
        this.selectedItems = {};
        for (const item of this.licenses) {
            this.selectedItems[item.id] = false;
        }
        this.allItemsChecked = false;
        this.checkSelectedItems();
    }

    /**
     * Check is there any item selected
     */
    checkSelectedItems() {
        this.anyItemSelected = _.some(_.values(this.selectedItems), (value) => value === true);
        _.filter(this.licenses, (license) => {
            return _.some(this.selectedItems, license.id);
        });
        // Offline download allowed for licenses that are attached to serial numbers
        this.offlineDownloadAllowed = _.every(
            _.filter(this.licenses, (license) => {
                return this.selectedItems[license.id];
            }), (license) => {
                return (license.serialNumber && license.serialNumber !== '');
            }
        );

        this.reattachLicenseAllowed = _.every(
            _.filter(this.licenses, (license) => {
                return this.selectedItems[license.id];
            }), (license) => {
                return (
                    // serialNumber and deviceId must be empty
                    (!license.serialNumber || license.serialNumber === '') && (!license.data.deviceId || license.data.deviceId === '')
                ) || (
                        // OR user role koy_user / sys_admin
                        _.get(this.user, ['permissions', 'actions', 'licenses.write']) && (_.get(this.user, 'role') === 'koy_user' || _.get(this.user, 'role') === 'sys_admin')
                    );
            }
        );
    }

    /**
     * Select / Deselect all items
     */
    selectAllItems(allChecked) {
        _.map(this.licenses, (item) => {
            this.selectedItems[item.id] = allChecked;
        });
        this.checkSelectedItems();
    }

    /**
     * Returns selected items
     */
    getSelectedItemsForBulk() {
        const items = _.map(
            _.filter(this.licenses, (license) => {
                return license && this.selectedItems[license.id] ? true : false;
            }),
            (item) => {
                return {
                    id: item.id
                };
            }
        );
        return items;
    }

    openAttachDialog(type) {
        // let value = '';
        let label = '';
        if (type === 'serial') {
            label = 'Serial number';
        } else if (type === 'device') {
            label = 'Device id';
        }
        // } else if (type === 'bundle') {
        //     value = this.randomStr(6, '1234567890ABCDEF');
        //     label = 'Bundle id';
        // }
        const items = _.filter(this.licenses, (license) => {
            return this.selectedItems[license.id];
        });

        const data = { user: this.user, items, type, label, value: '', secureId: '' };
        const dialogConfig: MatDialogConfig = { data };
        const dialogRef = this.dialog.open(LicenseAttachDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                // Convert empty strings to null
                // type, value, secureId
                this.attachSelectedItems(type, data.value !== '' ? data.value : null, data.secureId !== '' ? data.secureId : null);
            }
        });
    }

    downloadOffline() {
        this.loadingDownload = true;
        const items = this.getSelectedItemsForBulk();
        const orderNumber = _.find(this.licenses, { id: items[0].id }).orderNumber;
        const serialNumber = _.find(this.licenses, { id: items[0].id }).serialNumber;
        const sequenceNumber = this.orderPackageSequenceNumber || 0;
        const filename = `SWHUB_Order_${orderNumber}_${sequenceNumber}_SN${serialNumber}.zip`;
        this.softwaresService.downloadOffline(items, orderNumber, serialNumber, this.includeForcedVRD).subscribe((data) => {
            console.log('DOWNLOAD OFFLINE', filename);
            const type = 'application/zip';
            const content: Blob = this.b64toBlob(data, type);
            FileSaver.saveAs(content, filename);
        }, (error) => {
            console.log('error', error);
            let errorStatus = '';
            let errorMessage = '';
            if (error.status && error.error) {
                errorStatus = error.status;
                errorMessage = JSON.parse(error.error).errorMessage;
            }
            this.notifyError(`Failed to download software: [${errorStatus}] ${errorMessage}`);
            this.loadingDownload = false
        }, () => {
            this.loadingDownload = false;
        });
    }

    downloadFirmware(softwareId, revisionId) {
        this.loadingDownload = true;
        const software = _.find(this.firmwares, { id: softwareId });
        const revision = _.find(software.data.revisions, { id: revisionId });
        const filename = revision.file.filename;
        this.softwaresService.downloadSoftware(software.id, revision.id).subscribe((data: any) => {
            const type = 'application/zip';
            const content: Blob = this.b64toBlob(data, type);
            FileSaver.saveAs(content, filename);
        }, (error) => {
            console.log('error', error);
            let errorStatus = '';
            let errorMessage = '';
            if (error.status && error.error) {
                errorStatus = error.status;
                errorMessage = JSON.parse(error.error).errorMessage;
            }
            this.notifyError(`Failed to download software: [${errorStatus}] ${errorMessage}`);
            this.loadingDownload = false
        }, () => {
            this.loadingDownload = false;
        });
    }

    downloadRevisionWithTempUrl(softwareId, revisionId) {
        const software = _.find(this.firmwares, { id: softwareId });
        const revision = _.find(software.data.revisions, { id: revisionId });
        this.softwaresService.getFileDownloadUrlFromAWSS3(software.id, revision.id).subscribe((result: IDownloadUrlResponse) => {
            if (result && result.temporaryDownloadUrl) {
                window.location.href = result.temporaryDownloadUrl;
            }
        }, (error) => {
            console.log('error', error);
            this.notifyError('Failed to download software: ' + (error.error.errorMessage || error.message));
        });
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    randomStr(len, arr) {
        let ans = '';
        for (let i = len; i > 0; i--) {
            ans += arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    attachSelectedItems(type, value = null, secureId = null) {
        this.loadingAttachment = true;
        const items = this.getSelectedItemsForBulk();
        this.licensesService.attachLicensesTo(items, type, value, secureId).subscribe((response) => {
            setTimeout(() => {
                this.notify('Licenses attached');
                this.search();
                this.loadingAttachment = false;
            }, 2000);
        }, (error) => {
            console.log('error', error);
            this.notifyError('Failed to attach licenses: ' + (error.error.errorMessage || error.message));
            // this.notifyError('Failed to attach licenses');
            this.loadingAttachment = false;
        });
    }

    openDeleteConfirmDialog() {
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.delete_confirmation',
                content: 'confirm_dialog.are_you_sure_you_want_to_delete'
            }
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                this.deleteSelectedItems();
            }
        });
    }

    deleteSelectedItems() {
        const items = this.getSelectedItemsForBulk();
        this.licensesService.deleteLicenses(items).subscribe((response) => {
            setTimeout(() => {
                this.search();
            }, 2000);
        });
    }

    changeSelectedLicensesActivity(active) {
        const items = this.getSelectedItemsForBulk();
        for (const item of items) {
            item.active = active;
        }
        this.licensesService.updateLicenses(items).subscribe((response) => {
            setTimeout(() => {
                this.search();
            }, 2000);
        });
    }

    notify(message, duration = 3000) {
        this.snackBar.open(message, 'OK', {
            duration,
            panelClass: ['war-snackbar']
        });
    }

    notifyError(message, duration = 5000) {
        this.snackBar.open(message, 'X', {
            duration,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }

}
