import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function loadJSON(filename, callback) {
    const xobj = new XMLHttpRequest();
    xobj.overrideMimeType('application/json');
    xobj.open('GET', filename, true);
    xobj.onreadystatechange = function() {
        if (xobj.readyState === 4 && xobj.status === 200) {
            callback(null, xobj.responseText);
        } else if (xobj.readyState === 4 && xobj.status !== 200) {
            callback(true);
        }
    };
    xobj.send(null);
}

const date = new Date().getTime();
loadJSON('config.json?' + date, function(error, config) {
    if (error || !config) {
        console.error('Configuration file empty! Ensure a proper config.json is on the website root.');
        return;
    }
    if (typeof config === 'string') {
        try {
            config = JSON.parse(config);
        } catch (e) {
            console.error('Unable to parse configuration file! Ensure a proper config.json is on the website root.', e);
            return;
        }
    }

    // attach config to global namespace and ensure that
    // the config is loaded before bootstrapping angular
    window['config'] = config;

    // console.log('config loaded', window['config']);

    if (environment.production) {
        enableProdMode();
    }
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
});
