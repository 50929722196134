import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

import { ISoftware } from '../models/ISoftware';

import * as _ from 'lodash';
import { ICreateRequest, IUpdateRequest, IDeleteRequest, IUploadUrlRequest, IParseKbfRequest, IDownloadRequest } from '../models/ISWSoftwareApi';

@Injectable()
export class SoftwaresService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.swhubBackendUrl;
        this.token = this.authService.accessToken;
    }

    getSoftware(id: string) {
        console.log('getSoftware()');
        return this.http.get(`${this.BACKENDURL}/softwares/${id}`, { headers: this._getHeaders() });
    }

    getSoftwares(searchParams) {
        const sort = 'version.modified.timestamp:desc';
        searchParams.push({ key: 'sort', value: sort });
        searchParams.push({ key: 'size', value: 1000 });
        return this.http.get(`${this.BACKENDURL}/softwares?${this._parseSearchParamsToString(searchParams)}`, { headers: this._getHeaders() });
    }

    getSoftwareByProductNumber(productNumber) {
        console.log('getSoftwareByProductNumber()', productNumber);
        return this.http.get(`${this.BACKENDURL}/softwares/product/${productNumber}`, { headers: this._getHeaders() });

    }

    getFirmwaresByProductFamily(productFamily) {
        console.log('getFirmwaresByProductFamily()', productFamily);
        const sort = 'version.modified.timestamp:desc';
        return this.http.get(`${this.BACKENDURL}/softwares/firmwares/${productFamily}?sort=${sort}`, { headers: this._getHeaders() });
    }

    createSoftware(body: ICreateRequest) {
        return this.http.post(`${this.BACKENDURL}/softwares`, body, { headers: this._getHeaders() });
    }

    updateSoftware(body: IUpdateRequest) {
        return this.http.put(`${this.BACKENDURL}/softwares/${body.id}`, body, { headers: this._getHeaders() });
    }

    deleteSoftware(id: string) {
        return this.http.delete(`${this.BACKENDURL}/softwares/${id}`, { headers: this._getHeaders() });
    }

    getFileUploadUrlToAWSS3(body: IUploadUrlRequest) {
        return this.http.post(`${this.BACKENDURL}/softwares/upload`, body, { headers: this._getHeaders() });
    }

    uploadFileToAWSS3(fileUploadUrl, contentType, file) {
        const headers = new HttpHeaders({
            'Content-Type': contentType
        });
        return this.http.put(fileUploadUrl, file, { headers, reportProgress: true });
    }

    getFileDownloadUrlFromAWSS3(softwareId, revisionId = null) {
        return this.http.get(`${this.BACKENDURL}/softwares/download/${softwareId}/${revisionId}?tempurl=true`, { headers: this._getHeaders() });
    }

    downloadFileFromAWSS3(fileDownloadUrl) {
        const headers = new HttpHeaders();
        return this.http.get(fileDownloadUrl, { headers, reportProgress: true });
    }

    downloadSoftware(softwareId, revisionId = null) {
        return this.http.get(`${this.BACKENDURL}/softwares/download/${softwareId}/${revisionId}`, { responseType: 'text', headers: this._getHeaders() });
    }

    downloadOffline(items: Array<{ id: string }>, orderNumber: string, serialNumber: string, includeForcedVRD: boolean = false) {
        const body: IDownloadRequest = {
            offline: true,
            serialNumber,
            orderNumber,
            licenseIds: _.map(items, 'id'),
            includeForcedVRD
        };
        return this.http.post(`${this.BACKENDURL}/softwares/download`, body, { responseType: 'text', headers: this._getHeaders() });
    }

    parseKbf(body: IParseKbfRequest) {
        return this.http.post(`${this.BACKENDURL}/softwares/kbf`, body, { headers: this._getHeaders() });
    }

    getFileIdMapping() {
        return this.http.get(`${this.BACKENDURL}/softwares/fileids`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
        });
    }

    _parseSearchParamsToString(searchParams) {
        const params = searchParams.map(param => `${param.key}=${param.value}`);
        return params.join('&');
    }

}
