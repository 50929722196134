import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

import { LicensesPageComponent } from './components/licenses-page/licenses-page.component';
import { OrderPageComponent } from './components/order-page/order-page.component';

import { LicenseAttachDialogComponent } from './components/licenses-page/license-attach-dialog/license-attach-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule,
        MatProgressSpinnerModule, MatRadioModule, MatCheckboxModule, MatChipsModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule,
        MatTabsModule, MatDialogModule,
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule
    ],
    declarations: [
        LicensesPageComponent,
        OrderPageComponent,
        LicenseAttachDialogComponent
    ],
    entryComponents: [
        LicenseAttachDialogComponent
    ],
    exports: [
        LicensesPageComponent,
        OrderPageComponent
    ]
})
export class LicensesModule { }
