<div class="main">
    <div class="list-container">
        <div class="full main-header flex-row gap-20">
            <mat-icon class="kemppi-symbols">Ē</mat-icon>
            <div class="flex-column">
                <h2>{{ 'devices.devices_topic' | translate }}</h2>
                <div>{{ 'devices.devices_description' | translate }}</div>
            </div>
        </div>

        <div class="full flex-row flex-wrap gap-20">
            <div class="flex-column flex-wrap gap-20">
                <h2 class="mat-subhead">{{ 'common.search' | translate }}</h2>
                <div class="flex-row flex-wrap gap-20">
                    <mat-form-field *ngIf="permissions.scope === 'global'" style="width:300px">
                        <mat-label>{{ 'common.company' | translate }}</mat-label>
                        <mat-select [(value)]="searchCompany" (selectionChange)="search()">
                            <mat-option value="">{{ 'common.any' | translate }}</mat-option>
                            <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} -
                                {{ company.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="permissions.scope === 'global' || permissions.scope === 'company'">
                        <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                        <input matInput [(ngModel)]="searchPartnerId" (change)="search()">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'common.name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="searchName" (change)="search()">
                    </mat-form-field>
                    <div>
                        <button mat-button color="warn"
                            (click)="resetSearchParams(); search()">{{ 'common.reset' | translate }}</button>
                    </div>
                </div>
            </div>
            <div class="flex-column flex-wrap">
                <h2 class="mat-subhead">Kemppi Connect</h2>
                <a href="https://play.google.com/store/apps/details?id=io.kemppi.connect" target="_blank"><img src="assets/images/google-play.png" style="width: 130px; height: 50px"></a>
            </div>
        </div>

        <button *ngIf="permissions.actions['devices.write']" mat-button mat-raised-button color="accent" (click)="getToken()">
            <mat-icon>add</mat-icon>
            {{ 'devices.add_new_mobile_devices' | translate }}
        </button>

        <div *ngIf="activationToken" class="flex-column align-center">
            <div>
                {{ 'devices.open_kemppi_connect_app_for_reading' | translate }}<br>
            </div>
            <div>
                <ks-barcode [(ngModel)]="activationToken" [qrCodeSize]="1.4" type="2D"></ks-barcode>
            </div>
            <div *ngIf="user && user.role && (user.role === 'koy_user' || user.role === 'sys_admin')">
                <p class="weak">Device will be added to<br>
                company: {{ user.company }}<br>
                partnerId: {{ user.partnerId }}</p>
            </div>
        </div>
 
        <h2 class="mat-subhead">
            {{ 'devices.devices_topic' | translate }}
            <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company') && searchPartnerId === ''" class="warn">INCLUDING CUSTOMER DEVICES</div>
        </h2>
        <div class="list-header-row flex-row">
            <div class="list-column-icon">
            </div>
            <div class="list-column-company">
                <small>{{ 'common.company' | translate }}</small>
            </div>
            <div class="list-column-partnerid">
                <small>{{ 'common.partner_id' | translate }}</small>
            </div>
            <div class="list-column-name">
                <small>{{ 'common.name' | translate }}</small>
            </div>
            <div class="list-column-brand hide-in-sm">
                <small>{{ 'common.brand' | translate }}</small>
            </div>
            <div class="list-column-sdk hide-in-sm">
                <small>Android sdk v.</small>
            </div>
            <div class="list-column-activated hide-in-sm">
                <small>{{ 'common.activated' | translate }}</small>
            </div>
            <div class="list-column-id hide-in-sm">
                <small>{{ 'common.id' | translate }}</small>
            </div>
        </div>
        <div>
            <mat-progress-spinner matSuffix *ngIf="loadingDevices" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
        </div>
        <div *ngIf="!loadingDevices && devices" class="flex-column full">
            <ng-container *ngFor="let device of devices">
                <div *ngIf="device && (!device.data || !device.data.device) && permissions.scope === 'global'">
                    <div class="flex-row NOT-ACTIVE">
                        {{ device | json}}
                    </div>
                </div>
                <div *ngIf="device && device.data && device.data.device" class="list-row flex-row align-center" [ngClass]="{'SELECTED-DEVICE': device.id === selectedDevice, 'NOT-ACTIVE': device.deleted}" (click)="selectedDevice === device.id ? selectedDevice = null : selectedDevice = device.id">
                    <div class="list-column-icon">
                        <mat-checkbox *ngIf="permissions.actions['devices.write']" [checked]="device.id === selectedDevice" (click)="$event.preventDefault();"></mat-checkbox>
                    </div>
                    <div class="list-column-company">
                        <small>{{device.company}}</small>
                    </div>
                    <div class="list-column-partnerid">
                        <small>{{device.partnerId}}</small>
                    </div>
                    <div class="list-column-name">
                        {{device.data.device.name}}
                    </div>
                    <div class="list-column-brand hide-in-sm">
                        {{device.data.device.brand}}
                    </div>
                    <div class="list-column-sdk hide-in-sm">
                        <small class="weaker">{{device.data.device.sdk}}</small>
                    </div>
                    <div class=list-column-activated hide-in-sm>
                        {{device.data.device.activated | date:'dd.MM.yyyy HH:mm'}}
                    </div>
                    <div class="list-column-id hide-in-sm">
                        <small class="weaker">{{device.id}}</small>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <ks-actionbar [hasCancel]="false" [hasSave]="false">
        <div class="flex-row full justify-center">
            <div *ngIf="selectedDevice && permissions.actions['devices.write']" class="align-center">
                <button mat-button mat-raised-button color="accent" (click)="openRenameDialog()">
                    <mat-icon>edit</mat-icon> {{ 'devices.rename_device' | translate }}
                </button>
                <button mat-button mat-raised-button color="warn" (click)="openDeleteConfirmDialog()">
                    <mat-icon>delete</mat-icon> {{ 'common.delete' | translate }}
                </button>
            </div>
        </div>
    </ks-actionbar>
</div>
