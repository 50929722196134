<div mat-dialog-title>
    <h3>
        <div *ngIf="data.type === 'serial'">{{ 'software.assign' | translate }}</div>
        <div *ngIf="data.type === 'device'">{{ 'software.attach_to_device' | translate }}</div>
    </h3>
    <div *ngIf="data.items && data.items[0] && data.items[0].data && data.items[0].data.bundleName && data.items[0].data.bundleIndex">
        {{ data.items[0].data.bundleName }} #{{ data.items[0].data.bundleIndex }}
    </div>
</div>
<div mat-dialog-content class="flex-column">
    <div *ngIf="data.type === 'device'" class="flex-row">
        <mat-progress-spinner matSuffix *ngIf="loadingDevices" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
        <mat-form-field *ngIf="devices" style="width:360px">
            <mat-label>{{ 'common.select' | translate }} {{ 'common.mobile_device' | translate }}</mat-label>
            <mat-select [(value)]="data.value" required>
                <mat-option *ngFor="let device of devices" [value]="device.id" class="flex-row">
                    <b style="display: inline-flex; width:240px">{{ device.data.device.name }}</b> {{ device.data.device.brand }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <div class="flex-row gap-20">
        <mat-form-field>
            <mat-label *ngIf="data.type === 'serial'">{{ 'common.serial_number' | translate }} ({{ 'common.power_source' | translate }})</mat-label>
            <mat-label *ngIf="data.type === 'device'">{{ 'common.mobile_device' | translate }}</mat-label>
            <input matInput [(ngModel)]="data.value">
        </mat-form-field>
        <mat-form-field *ngIf="data.type === 'serial'">
            <mat-label>{{ 'common.secure_id' | translate }}</mat-label>
            <input matInput [(ngModel)]="data.secureId">
        </mat-form-field>
    </div>

    <div *ngIf="detachLicenseAllowed">
        <small class="weak">* To detach software package licenses from {{data.type}}, leave fields blank!</small>
    </div>
</div>
<div mat-dialog-actions class="flex-row justify-center">
    <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
    <button mat-button mat-raised-button mat-dialog-close="ok" color="accent" cdkFocusInitial><span translate>common.ok</span></button>
</div>