<div class="main">
    <!-- SOFTWARE -->
    <div *ngIf="software" class="page-container flex-column">
        <div class="full main-header flex-row gap-20">
            <mat-icon class="kemppi-symbols">s</mat-icon>
            <div class="flex-column">
                <h2>{{ 'menu.maintain_software' | translate }}</h2>
                <div *ngIf="software" class="flex-column">
                    <h3><b>{{ software.data.name }}</b></h3>
                </div>
            </div>
        </div>

        <div class="full container">
            <div *ngIf="id === 'new' && !software.data.softwareType" class="flex-column">
                <h2 class="mat-subhead">{{ 'maintain_software.create_new_software' | translate }}</h2>
                <div class="flex-row gap-20">
                    <div class="flex-column">
                        <h3 class="mat-subhead">{{ 'common.manually' | translate }}</h3>
                        <div class="flex-column">
                            <mat-form-field>
                                <mat-label>{{ 'common.software_type' | translate }}</mat-label>
                                <mat-select [(value)]="software.data.softwareType"
                                    (selectionChange)="checkSoftwareTypeAndProductFamily()" [disabled]="software.id"
                                    required>
                                    <mat-option *ngFor="let softwareType of softwareTypes" [value]="softwareType">{{
                                        softwareType }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex-column">
                        <h3 class="mat-subhead">{{ 'maintain_software.read_from_kbf_file' | translate }}</h3>
                        <div class="flex-column">
                            <div class="flex-row flex-wrap gap-20">
                                <input id="parse-kbf" type="file" ng2FileSelect [hidden]="true"
                                    (change)="selectedFile($event, 0)">
                                <label class="mat-button mat-primary" color="primary" mat-button for="parse-kbf">
                                    {{ 'common.select_file' | translate }}
                                </label>
                            </div>
                        </div>
                        <small><i>({{ 'maintain_software.works_only_with' | translate }}: ACTIVATE FUNCTION / WELDING
                                PROGRAM)</i></small>
                    </div>
                </div>

            </div>
            <ng-container *ngIf="software.data.softwareType">
                <h2 class="mat-subhead">{{ 'maintain_software.header_information' | translate }}</h2>
                <div class="flex-column">
                    <div class="flex-row flex-wrap gap-20">
                        <mat-form-field>
                            <mat-label>{{ 'common.software_type' | translate }}</mat-label>
                            <mat-select [(value)]="software.data.softwareType"
                                (selectionChange)="checkSoftwareTypeAndProductFamily()" [disabled]="software.id"
                                required>
                                <mat-option *ngFor="let softwareType of softwareTypes" [value]="softwareType">{{
                                    softwareType }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'common.product_family' | translate }}</mat-label>
                            <mat-select [(value)]="software.data.productFamily"
                                (selectionChange)="checkSoftwareTypeAndProductFamily(); updateCompatibleProducts()"
                                [disabled]="software.id" required>
                                <mat-option *ngFor="let productFamily of productFamilies" [value]="productFamily">{{
                                    productFamilyNames[productFamily] }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="software.data.productFamily && compatibility[software.data.productFamily]">
                            <mat-label>{{ 'common.compatibility' | translate }}</mat-label>
                            <mat-select [(value)]="software.data.compatibility"
                                (selectionChange)="updateCompatibleProducts()" multiple>
                                <mat-option
                                    *ngFor="let subSystem of compatibility[software.data.productFamily].subSystems"
                                    [value]="subSystem">{{ subSystem }}</mat-option>
                            </mat-select>
                            <mat-hint
                                *ngIf="!software.data.compatibility || (software.data.compatibility && software.data.compatibility.length === 0)">*
                                empty = compatible to all</mat-hint>
                        </mat-form-field>
                        <div *ngIf="productNames && compatibleProducts">
                            <button mat-button class="smaller-button"
                                (click)="showCompatibleProducts = !showCompatibleProducts">
                                <mat-icon *ngIf="!showCompatibleProducts"
                                    class="drop-down-arrow">arrow_drop_down</mat-icon>
                                <mat-icon *ngIf="showCompatibleProducts"
                                    class="drop-down-arrow">arrow_drop_up</mat-icon>
                                Compatible products
                            </button>
                            <div *ngIf="showCompatibleProducts" class="compatibility">
                                <div *ngFor="let productNumber of compatibleProducts">
                                    {{ productNumber }} - {{ productNames[productNumber] }}
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow"></div>
                        <mat-form-field style="width: 340px;" [attr.readonly]="true">
                            <mat-label>ID</mat-label>
                            <input matInput [(ngModel)]="software.id" readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex-row flex-wrap gap-20">
                        <mat-form-field>
                            <mat-label>{{ 'common.name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="software.data.name" required>
                        </mat-form-field>
                        <mat-form-field style="width: 340px;">
                            <mat-label>{{ 'common.description' | translate }}</mat-label>
                            <input matInput [(ngModel)]="software.data.description">
                        </mat-form-field>
                    </div>
                    <div class="flex-row flex-wrap gap-20">
                        <mat-form-field>
                            <mat-label>{{ 'common.product_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="software.data.productNumber" (change)="updateProductName()"
                                [required]="software.data.softwareType === 'ACTIVATED_FUNCTION' || software.data.softwareType === 'WELDING_PROGRAM' || software.data.softwareType === 'PROGRAM_PACK' || software.data.softwareType === 'FEATURE_PACK'"
                                [disabled]="software.data.softwareType !== 'ACTIVATED_FUNCTION' && software.data.softwareType !== 'WELDING_PROGRAM' && software.data.softwareType !== 'PROGRAM_PACK' && software.data.softwareType !== 'FEATURE_PACK'">
                        </mat-form-field>
                        <mat-form-field style="width: 340px;">
                            <mat-label>{{ 'common.product_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="software.data.productName"
                                [required]="software.data.softwareType === 'ACTIVATED_FUNCTION' || software.data.softwareType === 'WELDING_PROGRAM' || software.data.softwareType === 'PROGRAM_PACK' || software.data.softwareType === 'FEATURE_PACK'"
                                [disabled]="software.data.softwareType !== 'ACTIVATED_FUNCTION' && software.data.softwareType !== 'WELDING_PROGRAM' && software.data.softwareType !== 'PROGRAM_PACK' && software.data.softwareType !== 'FEATURE_PACK'">
                        </mat-form-field>
                        <div>
                            <button mat-button class="smaller-button" matTooltip="Update product name from Machinery"
                                (click)="updateProductName()" [disabled]="!software.data.productNumber">
                                <mat-icon>refresh</mat-icon>
                                {{ 'common.update' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="flex-row flex-wrap gap-20">
                        <mat-form-field>
                            <mat-label>SW code</mat-label>
                            <input matInput [(ngModel)]="software.data.swCode">
                        </mat-form-field>
                    </div>
                    <div class="flex-row flex-wrap gap-20">
                        <mat-checkbox [(ngModel)]="software.data.private">Private / Restricted</mat-checkbox>
                        <ng-container *ngIf="software.data.private">
                            <mat-form-field>
                                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                                <mat-chip-list #chipList>
                                    <mat-chip
                                        *ngFor="let partnerId of software.data.restrictedPartnerId; let pIndex = index"
                                        (click)="removeRestrictedParterId('software.data.restrictedPartnerId', pIndex)">
                                        <b>{{partnerId}}</b>
                                    </mat-chip>
                                </mat-chip-list>
                                <input matInput class="flex-grow" [matChipInputFor]="chipList"
                                    [(ngModel)]="restrictedPartnerId"
                                    (keydown)="restrictedChipKeyEvent($event, 'software.data.restrictedPartnerId', 'restrictedPartnerId')"
                                    (change)="addRestrictedParterId('software.data.restrictedPartnerId', 'restrictedPartnerId')"
                                    [disabled]="!software.data.private">
                            </mat-form-field>
                            <mat-form-field id="restricted-company">
                                <mat-label>{{ 'common.company' | translate }}</mat-label>
                                <mat-select [(value)]="software.data.restrictedCompany" multiple
                                    [disabled]="!software.data.private">
                                    <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }}
                                        - {{ company.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                        <div *ngIf="!software.data.private" class="weak">({{ 'maintain_software.visible_for_all_users' |
                            translate }})</div>
                        <div *ngIf="software.data.private" class="weak">({{ 'maintain_software.visible_for_partners' |
                            translate }} {{ software.data.restrictedPartnerId || 'ANY' | json }} / {{
                            software.data.restrictedCompany || 'ANY' | json }})</div>
                    </div>

                    <br>
                    <h2 class="mat-subhead">{{ 'common.revisions' | translate }}</h2>
                    <div *ngFor="let swRev of software.data.revisions; let i = index" class="flex-column width100">
                        <div class="flex-row width100">
                            <fieldset [ngClass]="{'sub-fieldset': !showRevision[i]}" class="width100">
                                <legend (click)="toggleShowRevision(i)" [ngClass]="{'inactive': swRev.inactive}"
                                    class="legend-button cursor-pointer flex-row align-center">
                                    <mat-icon *ngIf="showRevision[i]" class="drop-down-arrow">arrow_drop_up</mat-icon>
                                    <mat-icon *ngIf="!showRevision[i]"
                                        class="drop-down-arrow">arrow_drop_down</mat-icon>
                                    <h3 class="flex-row flex-wrap align-center">
                                        <div class="revision-topic-label">{{ 'common.rev' | translate }}</div>
                                        <div class="revision-topic-value">{{ swRev.revisionNumber }}</div>
                                        <div class="revision-topic-label">{{ 'common.ver' | translate }}</div>
                                        <div class="revision-topic-value">{{ swRev.versionNumber }}</div>
                                        <div class="revision-topic-label">{{ 'common.name' | translate }}:</div>
                                        <div class="revision-topic-label">{{ swRev.name }}</div>
                                        <div *ngIf="swRev.latestOfficial" class="revision-topic-label accent">* latest
                                            official revision</div>
                                        <div *ngIf="swRev.private" class="revision-topic-label">* private</div>
                                    </h3>
                                </legend>
                                <ng-container *ngIf="showRevision[i]">
                                    <div class="flex-row align-center justify-end weak gap-10">
                                        ID: {{ swRev.id }}
                                        <div>
                                            <button *ngIf="!swRev.inactive" mat-button color="warn"
                                                class="smaller-button" (click)="inactivateRevision(i)">
                                                <mat-icon>remove_circle_outline</mat-icon> {{ 'common.deactivate' |
                                                translate }}
                                            </button>
                                        </div>
                                        <div>
                                            <button *ngIf="swRev.inactive" mat-raised-button color="accent"
                                                class="smaller-button" (click)="activateRevision(i)">
                                                <mat-icon>control_point</mat-icon> {{ 'common.activate' | translate }}
                                            </button>
                                        </div>
                                        <div>
                                            <button mat-icon-button mat-raised-button color="warn"
                                                class="smaller-button" (click)="removeRevision(i)"
                                                [disabled]="!swRev.inactive">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex-row flex-wrap gap-20">
                                        <mat-form-field style="width: 40px;" readonly="true">
                                            <mat-label>{{ 'common.rev' | translate }}</mat-label>
                                            <input matInput [(ngModel)]="swRev.revisionNumber" type="number" min="1"
                                                max="255" step="1" readonly="true">
                                        </mat-form-field>
                                        <mat-form-field style="width: 100px;">
                                            <mat-label>{{ 'common.version_number' | translate }}</mat-label>
                                            <input matInput [(ngModel)]="swRev.versionNumber">
                                        </mat-form-field>
                                        <mat-form-field style="width: 340px;">
                                            <mat-label>{{ 'common.name' | translate }}</mat-label>
                                            <input matInput [(ngModel)]="swRev.name">
                                        </mat-form-field>
                                        <mat-form-field style="width: 340px;">
                                            <mat-label>{{ 'common.description' | translate }} / {{
                                                'common.release_notes' | translate }}</mat-label>
                                            <textarea matInput [(ngModel)]="swRev.description"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-row flex-wrap gap-20" *ngIf="!swRev?.file?.filename">
                                        <mat-form-field>
                                            <mat-label>{{ 'maintain_software.copy_contents_from_revision' | translate
                                                }}</mat-label>
                                            <mat-select [(value)]="copyFromRevisionIndex"
                                                (selectionChange)="copySoftwares(i)">
                                                <ng-container
                                                    *ngFor="let revision of software.data.revisions; let ix = index">
                                                    <mat-option *ngIf="ix !== i"
                                                        [value]="ix">{{revision?.revisionNumber}}</mat-option>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-row flex-wrap gap-20">
                                        <mat-checkbox [(ngModel)]="swRev.latestOfficial"
                                            (change)="checkLatestOfficial(swRev)">{{ 'common.latest_official_version' |
                                            translate }}</mat-checkbox>
                                    </div>
                                    <div class="flex-row flex-wrap gap-20">
                                        <mat-checkbox [(ngModel)]="swRev.private">{{ 'common.private' | translate }} /
                                            {{ 'common.restricted' | translate }}</mat-checkbox>
                                        <ng-container *ngIf="swRev.private">
                                            <mat-form-field>
                                                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                                                <mat-chip-list #chipListRev>
                                                    <mat-chip
                                                        *ngFor="let partnerId of software.data.revisions[i].restrictedPartnerId; let pIndex = index"
                                                        (click)="removeRestrictedParterId('software.data.revisions['+i+'].restrictedPartnerId', pIndex)">
                                                        <b>{{partnerId}}</b>
                                                    </mat-chip>
                                                </mat-chip-list>
                                                <input matInput class="flex-grow" [matChipInputFor]="chipListRev"
                                                    [(ngModel)]="restrictedPartnerIdRev[i]"
                                                    (keydown)="restrictedChipKeyEvent($event, 'software.data.revisions['+i+'].restrictedPartnerId', 'restrictedPartnerIdRev['+i+']')"
                                                    (change)="addRestrictedParterId('software.data.revisions['+i+'].restrictedPartnerId', 'restrictedPartnerIdRev['+i+']')"
                                                    [disabled]="!software.data.revisions[i].private">
                                            </mat-form-field>
                                            <mat-form-field>
                                                <mat-label>{{ 'common.company' | translate }}</mat-label>
                                                <mat-select [(value)]="swRev.restrictedCompany" multiple
                                                    [disabled]="!swRev.private">
                                                    <mat-option *ngFor="let company of companies"
                                                        [value]="company.id">{{ company.id }} - {{ company.name
                                                        }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>

                                        <div *ngIf="!swRev.private" class="weak">({{
                                            'maintain_software.visible_for_all_users' | translate }})</div>
                                        <div *ngIf="swRev.private" class="weak">({{
                                            'maintain_software.visible_for_partners' | translate }} {{
                                            swRev.restrictedPartnerId || 'ANY' | json }} / {{ swRev.restrictedCompany ||
                                            'ANY' | json }})</div>
                                    </div>

                                    <div *ngIf="software.data.softwareType === 'ACTIVATED_FUNCTION' || software.data.softwareType === 'WELDING_PROGRAM'"
                                        class="flex-column">
                                        <h2 class="mat-subhead separate-line">KBF {{ 'common.details' | translate }}
                                        </h2>
                                        <div class="flex-row flex-wrap gap-20 subcontent">
                                            <mat-form-field readonly="true">
                                                <mat-label>{{ 'common.file' | translate }} ID</mat-label>
                                                <input matInput [(ngModel)]="swRev.fileId" type="number"
                                                    [required]="software.data.softwareType === 'ACTIVATED_FUNCTION' || software.data.softwareType === 'WELDING_PROGRAM'"
                                                    [disabled]="software.data.softwareType !== 'ACTIVATED_FUNCTION' && software.data.softwareType !== 'WELDING_PROGRAM'"
                                                    readonly="true">
                                                <mat-hint *ngIf="swRev.fileId">hex: {{ swRev.fileIdHex }}</mat-hint>
                                            </mat-form-field>
                                            <mat-form-field readonly="true">
                                                <mat-label>{{ 'common.function' | translate }} ID</mat-label>
                                                <input matInput [(ngModel)]="swRev.functionId" type="number"
                                                    [required]="software.data.softwareType === 'ACTIVATED_FUNCTION'"
                                                    [disabled]="software.data.softwareType !== 'ACTIVATED_FUNCTION'"
                                                    readonly="true">
                                            </mat-form-field>
                                            <mat-form-field readonly="true">
                                                <mat-label>{{ 'common.curve_type' | translate }}</mat-label>
                                                <input matInput [(ngModel)]="swRev.curveType" type="number"
                                                    [required]="software.data.softwareType === 'WELDING_PROGRAM'"
                                                    [disabled]="software.data.softwareType !== 'WELDING_PROGRAM'"
                                                    readonly="true">
                                                <mat-hint *ngIf="swRev.curveType">hex: {{ swRev.curveTypeHex
                                                    }}</mat-hint>
                                            </mat-form-field>
                                            <mat-form-field readonly="true">
                                                <mat-label>{{ 'common.curve' | translate }} ID</mat-label>
                                                <input matInput [(ngModel)]="swRev.curveId" type="number"
                                                    [required]="software.data.softwareType === 'WELDING_PROGRAM'"
                                                    [disabled]="software.data.softwareType !== 'WELDING_PROGRAM'"
                                                    readonly="true">
                                                <mat-hint *ngIf="swRev.curveId">hex: {{ swRev.curveIdHex }}</mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <br>
                                    <div *ngIf="swRev.manifest">
                                        <h2 class="mat-subhead separate-line">{{ 'common.manifest' | translate }}</h2>
                                        <pre class="subcontent"
                                            style="width: 100%; white-space: break-spaces;">{{ swRev.manifest | json }}</pre>
                                    </div>

                                    <ng-container>
                                        <h2 class="mat-subhead separate-line">
                                            {{ 'common.file' | translate }}
                                            <small
                                                *ngIf="software.id && (software.data.softwareType === 'PROGRAM_PACK' || software.data.softwareType === 'FEATURE_PACK')">-
                                                (auto generated)</small>
                                        </h2>
                                        <div class="flex-row flex-wrap gap-20 subcontent">
                                            <mat-form-field style="width: 100%;" readonly="true">
                                                <mat-label>{{ 'common.filename' | translate }}</mat-label>
                                                <input matInput [(ngModel)]="swRev.file.filename" readonly="true">
                                            </mat-form-field>
                                            <mat-form-field style="width: 150px;" readonly="true">
                                                <mat-label>{{ 'common.content_type' | translate }}</mat-label>
                                                <input matInput [(ngModel)]="swRev.file.contentType" readonly="true">
                                            </mat-form-field>
                                            <mat-form-field style="width: 200px;" readonly="true">
                                                <mat-label>{{ 'common.modified' | translate }}</mat-label>
                                                <input matInput [(ngModel)]="swRev.file.lastModifiedDate"
                                                    readonly="true">
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="software.id" class="flex-row flex-wrap gap-20 subcontent">
                                            <mat-form-field style="width: 100%;" readonly="true">
                                                <mat-label>{{ 'maintain_software.filepath_in_storage' | translate
                                                    }}</mat-label>
                                                <input matInput [(ngModel)]="swRev.file.filepath" class="weak"
                                                    readonly="true">
                                            </mat-form-field>
                                        </div>

                                        <div *ngIf="software.id" class="flex-row flex-wrap gap-20">
                                            <ng-container
                                                *ngIf="software.data.softwareType !== 'PROGRAM_PACK' && software.data.softwareType !== 'FEATURE_PACK'">
                                                <input id="add-attachment-{{i}}" type="file" ng2FileSelect
                                                    [hidden]="true" (change)="selectedFile($event, i)">
                                                <label class="mat-button mat-primary" color="primary" mat-button
                                                    for="add-attachment-{{i}}">
                                                    {{ 'common.select_file' | translate }}
                                                </label>
                                                <button mat-button mat-raised-button color="accent"
                                                    [disabled]="!attachment || attachment.revisionIndex !== i"
                                                    (click)="saveAttachment(i)">{{
                                                    'maintain_software.upload_file_and_save_software' | translate
                                                    }}</button>
                                            </ng-container>
                                            <div class="flex-grow"></div>
                                            <div>
                                                <button mat-button color="accent"
                                                    (click)="downloadRevisionWithTempUrl(i)">
                                                    <mat-icon>cloud_download</mat-icon>
                                                    {{ 'common.download' | translate }}
                                                </button>
                                                <!--
                                                <button mat-button color="accent" (click)="downloadRevision(i)">
                                                    <mat-icon>cloud_download</mat-icon>
                                                    direct
                                                </button>
                                                -->
                                            </div>
                                        </div>

                                        <div *ngIf="!software.id && attachment" class="flex-row flex-wrap gap-20">
                                            <i><small>({{ 'maintain_software.save_software_to_upload_selected_file' |
                                                    translate }})</small></i>
                                        </div>
                                        <div *ngIf="!software.id && !attachment" class="flex-row flex-wrap gap-20">
                                            <i><small>({{ 'maintain_software.save_software_to_add_file' | translate
                                                    }})</small></i>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="(software.data.softwareType === 'PROGRAM_PACK' || software.data.softwareType === 'FEATURE_PACK') && softwaresForPack && software.id && swRev.includedSoftwares">
                                        <h2 class="mat-subhead separate-line">{{ 'common.pack' | translate }} - {{
                                            'common.included_software' | translate }}</h2>
                                        <div *ngFor="let incSoft of swRev.includedSoftwares; let incIndex = index"
                                            class="flex-row">
                                            <div style="width:120px">{{ incSoft.productNumber }}</div>
                                            <div style="width:240px">{{ incSoft.name }}</div>
                                            <div style="width:40px">{{ incSoft.revisionId }}</div>
                                            <div style="width:40px">{{ incSoft.versionNumber }}</div>
                                            <div style="width:240px"><small class="weak">{{ incSoft.filename }}</small>
                                            </div>
                                            <button mat-button color="warn" class="smaller-button"
                                                (click)="swRev.includedSoftwares.splice(incIndex, 1)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                        <div class="flex-row justify-end">
                                            <button mat-button class="smaller-button"
                                                matTooltip="Update included softwares"
                                                (click)="updateIncludedSoftwares(i)">
                                                <mat-icon>refresh</mat-icon>
                                                {{ 'common.update' | translate }}
                                            </button>
                                        </div>
                                        <fieldset [ngClass]="{'sub-fieldset': !showSelectIncludedSoftwares}">
                                            <legend (click)="showSelectIncludedSoftwares = !showSelectIncludedSoftwares"
                                                class="legend-button cursor-pointer align-center">
                                                <mat-icon *ngIf="showSelectIncludedSoftwares"
                                                    class="drop-down-arrow">arrow_drop_up</mat-icon>
                                                <mat-icon *ngIf="!showSelectIncludedSoftwares"
                                                    class="drop-down-arrow">arrow_drop_down</mat-icon>
                                                {{ 'common.select_software' | translate }}
                                            </legend>
                                            <ng-container *ngIf="showSelectIncludedSoftwares && softwaresForPack">
                                                <div *ngFor="let subSoft of softwaresForPack" class="flex-row">
                                                    <div style="width:120px">{{ subSoft.data.productNumber }}</div>
                                                    <div style="width:240px">{{ subSoft.data.name }}</div>
                                                    <div *ngFor="let subRevision of subSoft.data.revisions">
                                                        <button mat-button color="accent"
                                                            (click)="includeSoftware(subSoft, i, subSoft.id, subRevision.id)"
                                                            class="tiny-revision-button"
                                                            [ngClass]="{'active': _.findIndex(swRev.includedSoftwares, { softwareId: subSoft.id, revisionId: subRevision.id }) >= 0 }">r{{
                                                            subRevision.id }} {{ subRevision.versionNumber ?
                                                            'v'+subRevision.versionNumber : '' }} {{
                                                            subRevision.latestOfficial ? '*' : ' ' }}</button>
                                                    </div>
                                                    <!--
                                                    <pre>{{ subSoft | json }}</pre>
                                                    -->
                                                </div>
                                            </ng-container>
                                        </fieldset>
                                    </ng-container>
                                </ng-container>
                            </fieldset>
                        </div>
                    </div>
                    <div class="flex-row">
                        <div>
                            <button mat-button color="accent" (click)="addRevision()">
                                <mat-icon>add</mat-icon> {{ 'maintain_software.add_new_revision' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="software.id && (authUserRole === 'koy_user' || authUserRole === 'sys_admin')">
                <div class="flex-row full">
                    <fieldset class="flex-row full">
                        <legend>META</legend>
                        <div class="flex-row flex-wrap">
                            <div *ngIf="software.version.created" class="user-stamp">
                                <b>Schema</b><br>
                                {{software.schema?.type}}<br>
                                {{software.schema?.version}}
                            </div>
                            <div *ngIf="software.version.created" class="user-stamp">
                                <b>{{ 'common.created' | translate }}</b><br>
                                {{software.version.created.timestamp | date:'short':undefined:localeService.locale}}<br>
                                {{software.version.created.user?.name}}<br>
                                {{software.version.created.user?.email}}
                            </div>
                            <div *ngIf="software.version.modified" class="user-stamp">
                                <b>{{ 'common.modified' | translate }}</b><br>
                                {{software.version.modified.timestamp |
                                date:'short':undefined:localeService.locale}}<br>
                                {{software.version.modified.user?.name}}<br>
                                {{software.version.modified.user?.email}}
                            </div>
                        </div>
                    </fieldset>
                </div>
            </ng-container>

        </div>
    </div>
    <!--
    <pre>
DEBUG:
--- software ---
{{ software | json }}
    </pre>
-->
</div>


<ks-actionbar [hasCancel]="true" [hasSave]="true" [canSave]="!state.loading && !_.isEqual(software, state.originalData)"
    (save)="save()" (cancel)="cancel()">
    <div class="flex-row full justify-space-between">
        <ng-container *ngIf="(authUserRole === 'koy_user' || authUserRole === 'sys_admin')">
            <button mat-button color="warn" (click)="showKoyTools = !showKoyTools">
                <mat-icon class="kemppi-symbols">s</mat-icon>
            </button>
            <ng-container *ngIf="showKoyTools">
                <div *ngIf="software && software.id && !state.loading">
                    <button mat-button mat-raised-button color="warn" (click)="openDeleteConfirmDialog()"
                        [disabled]="!software.id">
                        <mat-icon>delete</mat-icon> {{ 'common.delete' | translate }}
                    </button>
                </div>
            </ng-container>
        </ng-container>

        <div class="flex-grow"></div>

        <div *ngIf="state && state.loading" class="flex-column justify-center" style="width: 100%">
            <div class="flex-row justify-center align-center" style="font-size: 14px;">
                {{state.loadingStatus}}
            </div>
            <div>
                <mat-progress-bar mode="buffer" [value]="state.loading"
                    [bufferValue]="state.loadingBuffer"></mat-progress-bar>
            </div>
        </div>
    </div>
</ks-actionbar>