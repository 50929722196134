import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { LicensesService } from 'src/app/services/licenses.service';
import { MachineryService } from 'src/app/services/machinery.service';
import { SoftwaresService } from 'src/app/services/softwares.service';

import { Company, Companies } from 'src/app/models/company';
import { IOrder } from 'src/app/models/IOrder';
import { ISoftware, EnabledSoftwarePackages } from 'src/app/models/ISoftware';
import { UserProfile } from 'src/app/models/user';

@Component({
    selector: 'app-order-page',
    templateUrl: './order-page.component.html',
    styleUrls: ['./order-page.component.scss']
})
export class OrderPageComponent implements OnInit {

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * User
     */
    user: UserProfile;

    /**
     * Source applications list
     * Default: SWHUB
     */
    sourceApplications: Array<{ key: string, description: string }> = [
        { key: 'SWHUB', description: '00 - SWHub / Manual' },
        { key: 'NO', description: '01 - Normal / Default' },
        { key: 'DEMO', description: '02 - DEMO' },
        { key: 'DS', description: '05 - Datastore' },
        { key: 'WEB', description: '07 - Web / eOrdering' },
        { key: 'CRM', description: '09 - Salesforce / Configurator' }
    ];

    validSwPackageNumbers = ['C5690', 'C5790', 'C5890']

    softwarePackageTypes = EnabledSoftwarePackages
    selectedSoftwarePackageType = this.softwarePackageTypes[0];

    /**
     * Products list, fetched from SWHub softwares
     */
    products: Array<{ productNumber: string, description: string, compatibility: string }>;
    productsCopy: Array<{ productNumber: string, description: string, compatibility: string }>;


    /**
     * Products form control for autocomplete selection
     */
    productsFormControl: Array<FormControl>;

    /**
     * Order
     */
    order: IOrder;

    /**
     * Loading states
     */
    loading: {
        [prop: string]: boolean
    };

    /**
     * Authenticated user scope
     */
    public authUserScope: string;

    /**
     * Show KOY tools
     */
    showKoyTools: boolean;

    /**
     * Advanced mode for KOY
     */
    advancedMode: boolean;

    /**
     * Is claim valid? Store validate() result
     */
    valid: boolean;

    constructor(
        private snackBar: MatSnackBar,
        public authService: AuthService,
        private licensesService: LicensesService,
        private machineryService: MachineryService,
        private softwaresService: SoftwaresService
    ) {
        this.companies = Companies;

        this.productsFormControl = [];
        this.productsFormControl.push(new FormControl());
        this.productsFormControl.push(new FormControl());

        this.showKoyTools = false;
        this.advancedMode = false;
    }

    ngOnInit() {
        this.loading = {
            customerInfo: false,
            placeOrder: false
        };

        this.user = this.authService.userProfile;
        if (this.user) {
            this.authUserScope = this.user.permissions.scope;
        }

        setTimeout(() => {
            this.order = this.getNewOrderTemplate();
            this.updateCustomerName();
            this.getSoftwares();
        });
    }

    /**
     * Get softwares, gets listing of possible softwares and product numbers for part selection
     */
    getSoftwares() {
        this.products = [];
        // this.products = [
        //     { productNumber: 'C5690', description: 'X5 Software Package' },
        //     { productNumber: 'C5600', description: 'X5 Package' }
        // ];
        this.softwaresService.getSoftwares([]).subscribe((softwares: Array<ISoftware>) => {
            if (softwares) {
                const products = [];
                for (const software of softwares) {
                    if (software.data.softwareType === 'ACTIVATED_FUNCTION'
                        || software.data.softwareType === 'WELDING_PROGRAM'
                        || software.data.softwareType === 'PROGRAM_PACK'
                        || software.data.softwareType === 'FEATURE_PACK') {
                        products.push({
                            productNumber: software.data.productNumber,
                            description: software.data.productName || software.data.name,
                            compatibility: software.data.compatibility ? software.data.compatibility.join(', ') : ''
                        });
                    }
                }
                this.products = _.filter(products, p => {
                    // Quick fix, remove WiseRoot+ and WiseThin+
                    // if (p.productNumber === 'X5500003' || p.productNumber === 'X5500004') {
                    //     return false;
                    // }
                    // return _.startsWith(p.productNumber, 'X55') || p.productNumber === 'X5FORCEDVRD';
                    return _.startsWith(p.productNumber, 'X5') || _.startsWith(p.productNumber, 'MSM') || _.startsWith(p.productNumber, 'AX');
                });
                // Add remarks
                // for (const p of this.products) {
                //     if (p.productNumber === 'X5500003' || p.productNumber === 'X5500004') {
                //         p.remark = 'PULSE ONLY';
                //     }
                // }
                this.products = _.orderBy(this.products, 'productNumber');
                this.productsCopy = JSON.parse(JSON.stringify(this.products));
                this.updateSoftwarePackageType(this.selectedSoftwarePackageType)
            }
        });
    }

    updateSoftwarePackageType(softwarePackage: typeof EnabledSoftwarePackages[number]) {

        /**
         * Mutate items in place
         */
        this.order.orderLines.forEach((o) => {
            if (!o.isSoftware) {
                o.description = softwarePackage.desc
                o.productNumber = softwarePackage.productCode
            }
        })

        const products = this.productsCopy
        let filteredProducts;
        switch (softwarePackage.family) {
            case 'MASTERMIG':
                filteredProducts = products.filter(p => p.productNumber.startsWith('MSM'))
                break;
            case 'X5MIGWELDER':
                filteredProducts = products.filter(p => p.productNumber.startsWith('X5'))
                break;
            case 'AXMIGWELDER':
                filteredProducts = products.filter(p => p.productNumber.startsWith('AX'))
                break;
        }

        this.products = filteredProducts
    }

    /**
     * Filter products on the fly in autocomplete
     */
    filterProducts(products, text) {
        return this.products.filter(option => {
            return option.productNumber.toLowerCase().includes(text.toLowerCase()) || option.description.toLowerCase().includes(text.toLowerCase());
        });
    }

    /**
     * Add new order line to order
     */
    addOrderLine(parentOrderLine = null) {
        const orderLineNumber = this.order.orderLines.length > 0 ? _.maxBy(this.order.orderLines, 'orderLine').orderLine + 1 : 1;
        if (parentOrderLine) {
            // Add software to package
            this.order.orderLines.push({
                orderLine: orderLineNumber,
                parentLine: parentOrderLine,
                productNumber: '',
                description: '',
                quantity: _.find(this.order.orderLines, { orderLine: parentOrderLine }).quantity,
                isSoftware: true,
                isVerified: true,
                packageName: _.find(this.order.orderLines, { orderLine: parentOrderLine }).packageName,
            });
        } else {
            // Add new package
            this.order.orderLines.push({
                orderLine: orderLineNumber,
                parentLine: orderLineNumber,
                productNumber: this.selectedSoftwarePackageType.productCode,
                description: this.selectedSoftwarePackageType.desc,
                quantity: 1,
                isSoftware: false,
                isVerified: true,
                packageName: '',
            });
        }
        this.productsFormControl.push(new FormControl());
    }

    /**
     * Remove order line from order
     */
    removeOrderLine(index) {
        const ol = this.order.orderLines[index];
        if (ol && ol.orderLine === ol.parentLine) {
            _.remove(this.order.orderLines, {
                parentLine: ol.parentLine
            });
        } else {
            this.order.orderLines.splice(index, 1);
        }
    }

    /**
     * Change quantity of order line
     * If its a parent line, update all childs
     */
    changeQuantity(orderLine) {
        for (const ol of this.order.orderLines) {
            if (ol.parentLine === orderLine.orderLine) {
                ol.quantity = orderLine.quantity;
            }
        }
    }

    /**
     * Change package name of order line
     * If its a parent line, update all childs
     */
    changePackageName(orderLine) {
        for (const ol of this.order.orderLines) {
            if (ol.parentLine === orderLine.orderLine) {
                ol.packageName = orderLine.packageName;
            }
        }
    }

    /**
     * Update product description
     * (After product number changed)
     */
    updateProductDescription(index) {
        const partNumber = this.order.orderLines[index].productNumber;
        if (partNumber) {
            const product = _.find(this.products, { productNumber: partNumber });
            if (product) {
                // Find from products (softwares)
                this.order.orderLines[index].description = product.description;
            } else {
                this.order.orderLines[index].description = '';
                // Find from machinery
                // this.machineryService.getPartInfo(partNumber).subscribe((partInfo: any) => {
                //     if (partInfo) {
                //         this.order.orderLines[index].description = partInfo.partDescription;
                //     } else {
                //         this.order.orderLines[index].description = '';
                //     }
                // });
            }
        }
    }

    /**
     * Update customer name
     * (After company / partnerId changed)
     */
    updateCustomerName() {
        const company = this.order.company;
        const partnerId = this.order.partnerId;
        if (company && partnerId) {
            this.loading.customerInfo = true;
            this.machineryService.getCustomerInfo(company, partnerId).subscribe((customerInfo: any) => {
                if (customerInfo) {
                    this.order.partnerName = customerInfo.name;
                } else {
                    this.notifyError(`Customer info not found`);
                    this.order.partnerName = '';
                }
                this.loading.customerInfo = false;
            }, (error) => {
                this.notifyError(`Customer info loading failed`);
                this.loading.customerInfo = false;
            });
        }
    }

    /**
     * Validate order, all required fields must be filled
     */
    validate() {
        let valid = true;
        if (!this.order.company) { valid = false; }
        if (!this.order.partnerId) { valid = false; }
        if (!this.order.partnerName) { valid = false; }
        for (const ol of this.order.orderLines) {
            if (!ol.orderLine) { valid = false; }
            if (!ol.parentLine) { valid = false; }
            if (!ol.productNumber) { valid = false; }
            if (!ol.description) { valid = false; }
            if (!ol.quantity) { valid = false; }
            if (!ol.packageName) { valid = false; }
            if (!this.validSwPackageNumbers.includes(ol.productNumber) && !_.find(this.products, { productNumber: ol.productNumber })) { valid = false; }
        }
        this.valid = valid;
    }

    placeOrder() {
        this.validate();
        if (this.valid === false) {
            this.notifyError(`All required fields must be filled and parts must be Software Hub supported`);
        } else {
            this.loading.placeOrder = true;

            /**
             * Remove duplicate items from order ( no need for those )
             */
            this.order.orderLines.forEach((software, i) => {
                this.order.orderLines.forEach((s, ix) => {
                    if (software.productNumber === s.productNumber && software.parentLine === s.parentLine && software.orderLine !== s.orderLine) {
                        this.order.orderLines.splice(i, 1)
                    }
                })
            })

            this.licensesService.createLicensesFromOrder(this.order).subscribe((response) => {
                this.notify(`${this.order.orderNumber} - Order sending succeeded\nSoftware licenses should be generated`);
                this.loading.placeOrder = false;
                this.order = this.getNewOrderTemplate();
                this.updateCustomerName();
            }, (error) => {
                this.notifyError(`Order sending failed`);
                this.loading.placeOrder = false;
            });
        }
    }

    getNewOrderTemplate() {
        const now = new Date();
        const orderTime = moment.utc(now).format('YYMMDDHHmmss');
        const order = {
            company: this.user.company,
            partnerId: this.user.partnerId,
            partnerName: '',
            orderNumber: 'SW' + orderTime,
            orderDate: now.toISOString(),
            referenceSource: 'SWHUB',
            referenceNumber: '',
            orderLines: [{
                orderLine: 1,
                parentLine: 1,
                productNumber: 'C5690',
                description: 'X5 Software Package',
                quantity: 1,
                isSoftware: false,
                isVerified: true
            },
            {
                orderLine: 2,
                parentLine: 1,
                productNumber: '',
                description: '',
                quantity: 1,
                isSoftware: true,
                isVerified: true
            }]
        };
        return order;
    }

    notify(message, duration = 5000) {
        this.snackBar.open(message, 'OK', {
            duration,
            panelClass: ['war-snackbar']
        });
    }

    notifyError(message, duration = 5000) {
        this.snackBar.open(message, 'X', {
            duration,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }
}
