import { Component, forwardRef, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _ from 'lodash';
import * as moment from 'moment';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WarDatepickerComponent),
    multi: true
};

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'war-datepicker',
    templateUrl: './war-datepicker.component.html',
    styleUrls: ['./war-datepicker.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class WarDatepickerComponent implements ControlValueAccessor {

    private innerValue: string;
    public innnerDisabled: boolean;

    @Input() label = null;
    @Input() showPicker = true;
    @Input() readonly = false;
    @Input() required = false;
    @Input() endOfDay = false;
    @Input() hint = null;
    @Input() hintclass: string = null;
    @Output() changeEvent = new EventEmitter();

    get value(): any {
        return this.innerValue;
    }

    set value(val: any) {
        if (val !== this.innerValue) {
            this.innerValue = val;
            this.onChange(val);
        }
    }

    onChange: any = () => { };

    onTouched: any = () => { };

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.innnerDisabled = isDisabled;
    }

    writeValue(val: any): void {
        if (val !== this.innerValue) {
            this.innerValue = val;
        }
    }

    changeDate(event: MatDatepickerInputEvent<any>) {
        // console.log('changeDate', event);
        const isoString: string = moment(event.value).toISOString();
        if (this.endOfDay) {
            this.value = isoString.replace('00:00:00.000', '23:59:59.999');
        } else {
            // 00:00:00.000
            this.value = isoString;
        }
        this.change();
    }

    change() {
        this.changeEvent.emit();
    }

}
