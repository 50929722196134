export interface Company {
    id: string;
    name: string;
}

export const Companies: Array<Company> = [
    { id: '100', name: 'KEMPPI OY' },
    { id: '109', name: 'KEMPPI AUSTRALIA PTY LIMITED' },
    { id: '130', name: 'KEMPPI SVERIGE AB' },
    { id: '140', name: 'KEMPPI NORGE AS' },
    { id: '150', name: 'KEMPPI A/S' },
    { id: '160', name: 'KEMPPI GMBH' },
    { id: '170', name: 'KEMPPI BENELUX B.V.' },
    { id: '180', name: 'KEMPPI (UK) LIMITED' },
    { id: '190', name: 'KEMPPI FRANCE S.A.S.' },
    { id: '210', name: 'KEMPPI SP. Z O.O.' },
    { id: '220', name: 'KEMPPI LLC' },
    { id: '240', name: 'KEMPPI WELDING TECHNOLOGY (BEIJING) CO. LTD.' },
    { id: '260', name: 'KEMPPI INDIA PRIVATE LIMITED' },
    { id: '270', name: 'KEMPPI WELDING SOLUTIONS SDN BHD' },
    { id: '280', name: 'KEMPPI WELDING TECHNOLOGY (WUXI) CO. LTD' },
    { id: '290', name: 'KEMPPI ITALY S.R.L' }
    // { id: '700', name: 'KEMPOWER OY' }
];

export const CompanyCodes: Array<string> = [
    '100', '109', '130', '140', '150', '160', '170', '180', '190', '210', '220', '240', '260', '270', '280', '290'
];
