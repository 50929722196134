<div class="main">
    <div class="list-container">
        <div class="full main-header flex-row align-center gap-20">
            <div *ngIf="!loginError" class="full flex-column align-center gap-20">
                <h2>Loading, please wait</h2>
            </div>
            <div *ngIf="loginError" class="full flex-column align-center gap-20">
                <h2>Login failed</h2>
                <p>Please try again or contact Kemppi Support for help</p>
                <br>

                <code>
                    <small *ngIf="loginError">{{ loginError }}</small>
                </code>
                <code>
                    <small *ngIf="loginErrorDescription"><i>{{ loginErrorDescription }}</i></small>
                </code>
                <br>
                <button mat-button routerLinkActive="active" routerLink="/login">
                    Login
                </button>

            </div>
        </div>
    </div>
</div>
<!-- ACTIONBAR -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
