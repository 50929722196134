import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-discard-confirm-dialog',
    template: `
        <h1>{{title | translate}}</h1>
        <div mat-dialog-content>
            <p translate>{{topic}}</p>
            <p><b>{{item}}</b></p>
            <p translate>{{text}}</p>
        </div>
        <div mat-dialog-actions class="flex-row justify-center">
          <button mat-raised-button mat-button color="primary" (click)="cancel()">{{ cancelText | translate }}</button>
          <button mat-raised-button mat-button color="accent"  (click)="ok()">{{ okText | translate }}</button>
        </div>
    `
})
export class DiscardConfirmDialogComponent implements OnInit {
    item: any;
    title = 'confirm_dialog.discard_changes';
    topic = 'confirm_dialog.page_contains_unsaved_changes';
    text = 'confirm_dialog.are_you_sure_you_want_to_discard_them';
    okText = 'confirm_dialog.discard';
    cancelText = 'confirm_dialog.cancel';

    constructor(public dialogRef: MatDialogRef<any>) {

    }

    ngOnInit() {
    }

    ok() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
