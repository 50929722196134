<div (click)="state.isMenuOpen = false; state.isUserMenuOpen=false" *ngIf="state.isMenuOpen || state.isUserMenuOpen" id="backdrop"></div>
<mat-toolbar [class.child-view]="hasMenu" color="primary" class="ks-toolbar">
    <button [class.hidden]="!hasMenu" [disabled]="!hasMenu" *ngIf="!hasBack"
            (click)="state.isMenuOpen = !state.isMenuOpen" mat-icon-button>
        <mat-icon id="app-menu" class="kemppi-symbols">×</mat-icon>
    </button>
    <button *ngIf="hasBack" (click)="emitBack()" mat-icon-button>
        <mat-icon color="accent" id="app-menu" class="kemppi-symbols">³</mat-icon>
    </button>

    <mat-list class="custom-menu" *ngIf="state.isMenuOpen">
        <!-- Modules -->
        <a *ngIf="allowedModules['licenses']" mat-list-item (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/software">
            <mat-icon class="kemppi-symbols">O</mat-icon>
            <span>{{ 'menu.software' | translate }}</span>
        </a>
        <a *ngIf="allowedModules['devices']" mat-list-item (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/mobile-devices">
            <mat-icon class="kemppi-symbols">Ē</mat-icon>
            <span>{{ 'menu.mobile_devices' | translate }}</span>
        </a>
        <ng-container *ngIf="authUserScope === 'global' || authUserScope === 'company'">
            <mat-list-item class="topic-item">
                <mat-icon class="kemppi-symbols">©</mat-icon>
                <span>{{ 'menu.management_tools' | translate }}</span>
            </mat-list-item>

            <a *ngIf="allowedModules['licenses'] &&  (authUserScope === 'global' || authUserScope === 'company')" mat-list-item (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/order">
                <mat-icon class="kemppi-symbols">É</mat-icon>
                <span>{{ 'menu.order' | translate }}</span>
            </a>

            <a *ngIf="allowedModules['softwares']" mat-list-item (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/maintain-software">
                <mat-icon class="kemppi-symbols">s</mat-icon>
                <span>{{ 'menu.maintain_software' | translate }}</span>
            </a>
        </ng-container>

    </mat-list>

    <div class="flex-row flex-grow">
        <img fxHide.lt-md src="assets/images/Kemppi_logo_negative_transparent.png" class="logo">
        <!--
        <mat-icon id="app-icon" fxShow.lt-sm="false" class="kemppi-symbols">{{appInfo.icon}}</mat-icon>
        -->
        <h2 id="app-title" fxShow.lt-sm="false">
            <a routerLink="">{{appInfo.title | translate}}</a>
        </h2>

        <ng-container *ngIf="subTitle && (!file || file === '')">
            <h2 class="app-subtitle">
                <span fxShow.lt-sm="false" class="kemppi-symbols-inline">÷</span>
                {{ 'menu.'+subTitle | translate}}
            </h2>
        </ng-container>

        <ng-container *ngIf="subTitle && (file && file !== '')">
            <h2 class="app-subtitle" fxShow.lt-sm="false">
                <span class="kemppi-symbols-inline">÷</span>
                <a [routerLink]="parentLink">{{ 'menu.'+subTitle | translate}}</a>
            </h2>
            <h2 class="app-subtitle">
                <span fxShow.lt-sm="false" class="kemppi-symbols-inline">÷</span>
                {{file}}
            </h2>
        </ng-container>
    </div>

    <!-- This fills the remaining space of the current row -->

    <div class="flex-row">
        <!--
        <button mat-icon-button
                class="help"
                aria-label="Help"
                fxShow.lt-sm="false">
            <mat-icon class="kemppi-symbols account">~</mat-icon>
        </button>
        -->

        <section class="tool-bar-user flex-row align-center" *ngIf="!state.loading">

            <div class="flex-column align-end">
                <section class="name"><strong>{{authService.userProfile?.name}}</strong></section>
                <section *ngIf="authService.userProfile" class="email">{{authService.userProfile?.partnerId}} / {{authService.userProfile?.company}} <small>( {{authService.userProfile?.role }} )</small></section>
            </div>

            <button mat-icon-button
                    (click)="state.isUserMenuOpen = !state.isUserMenuOpen"
                    class="account align-center"
                    aria-label="Account">
                <section *ngIf="!state.loading" class="flex-column align-center">
                    <mat-icon class="kemppi-symbols account">¢</mat-icon>
                    <mat-icon class="kemppi-symbols dropdown">ù</mat-icon>
                </section>
            </button>

            <mat-list class="user-menu" *ngIf="state.isUserMenuOpen">
                <a mat-list-item (click)="state.isLanguageMenuOpen = !state.isLanguageMenuOpen">
                    <span translate>change language</span>
                </a>
                <div class="user-menu-container">
                    <mat-list class="user-menu" *ngIf="state.isLanguageMenuOpen" id="language-menu">
                        <a mat-list-item (click)="setLanguage('en')" id="en">
                            <span translate>base.locales.en</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('da')" id="da">
                            <span translate>base.locales.da</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('de')" id="de">
                            <span translate>base.locales.de</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('zh')" id="zh">
                            <span translate>base.locales.zh</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('es')" id="es">
                            <span translate>base.locales.es</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('fi')" id="fi">
                            <span translate>base.locales.fi</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('fr')" id="fr">
                            <span translate>base.locales.fr</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('it')" id="it">
                            <span translate>base.locales.it</span>
                        </a>
                        <!--
                        <a mat-list-item (click)="setLanguage('ja')" id="ja">
                            <span translate>base.locales.ja</span>
                        </a>
                        -->
                        <a mat-list-item (click)="setLanguage('nl')" id="nl">
                            <span translate>base.locales.nl</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('nb')" id="nb">
                            <span translate>base.locales.nb</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('pl')" id="pl">
                            <span translate>base.locales.pl</span>
                        </a>
                        <!--
                        <a mat-list-item (click)="setLanguage('pt')" id="pt">
                            <span translate>base.locales.pt</span>
                        </a>
                        -->
                        <a mat-list-item (click)="setLanguage('ru')" id="ru">
                            <span translate>base.locales.ru</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('sv')" id="sv">
                            <span translate>base.locales.sv</span>
                        </a>
                    </mat-list>
                </div>

                <a *ngIf="!authService.authenticated()" mat-list-item (click)="login()">
                    <span translate>login</span>
                </a>
                <a *ngIf="authService.authenticated()" mat-list-item (click)="logout()">
                    <span translate>logout</span>
                </a>
            </mat-list>



        </section>
    </div>
</mat-toolbar>
