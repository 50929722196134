import * as JSZip from 'jszip';
import { Injectable } from '@angular/core';
import { FirmwarePackManifest } from '../models/firmware-manifest';

// NOTE: tsconfig.app.json > compilerOptions.paths.jszip

@Injectable()
export class ZipReaderService {

    constructor() { }

    async readTxtFileFromArchive(data, filename: string) {
        const zip = await JSZip.loadAsync(data);
        console.log({ zip });

        const zipFile = zip.file(filename);
        if (zipFile) {
            const fileContent = await zipFile.async('string');
            console.log({ fileContent });
    
            return fileContent;
        } else {
            console.log({ filename }, 'Not found from zip');
        }
    }

    /**
     * Find manifest file from zip archive and convert to JS format.
     *
     * Manifest:
     *      name=package name\n
     *      description=content description\n
     *      ...
     *
     * Output:
     *      {
     *          name: 'package name',
     *          description: 'content description'
     *          ...
     *      }
     * @param data Zip archive as Buffer
     * @param filename File what to looking from archive 'Manifest.txt'
     */
    async getManifest(data, filename: string): Promise<FirmwarePackManifest> {

        const fileContent = await this.readTxtFileFromArchive(data, filename);
        if (fileContent) {
            // Split from line changes
            const fileRows = fileContent.split(/\n/g);

            const manifest: FirmwarePackManifest = {};
            for (const row of fileRows) {
                // Split from first = char to [key] = [value]
                const r = row.replace(/\=/, '[BREAK]').split('[BREAK]');
                if (r && r.length === 2) {
                    const key = r[0].trim();
                    const value = r[1].trim();
                    if (key !== '') {
                        // Append to manifest
                        manifest[key] = value;
                    }
                }
            }

            return manifest;
        }
    }
}
