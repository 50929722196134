<div class="main">
    <div class="full flex-row align-center justify-center gap-20">
        <div class="select-login-container">
            <h2>Select login</h2>
            <div class="flex-column">
                <small><b><i>New login</i></b></small>
                <div class="flex-row gap-20">
                    <button mat-raised-button color="accent" (click)="loginWithAuth0()" class="login-with-button">
                        <span class="narrow-text">Log in with</span>
                        MyKemppi - my.kemppi.com
                    </button>
                </div>
                <small><i>If you don't have new login credentials yet, go to login screen and select Sign Up.</i></small>
                <br>
                <small><b><i>Old login</i></b></small>
                <div class="flex-row gap-20">
                    <button mat-raised-button color="accent" (click)="loginWithSalesforce()" class="login-with-button">
                        <span class="narrow-text">Log in with</span>
                        MyKemppi - kemppi.force.com
                    </button>
                </div>
                <small><i>This login will be retired and no longer supported after January 29, 2021.</i></small>
            </div>
            <mat-progress-spinner matSuffix *ngIf="loading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
        </div>
    </div>
</div>
<!-- ACTIONBAR BUTTONS -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
