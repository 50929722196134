<svg *ngIf="type === '1D'" #ksBarcode></svg>
<div *ngIf="type === '2D'" class="qrcode" #ksBarcode></div>
<div *ngIf="editable" class="input">
    <span *ngIf="!editing && !readOnly" class="text" (click)="onClick()" [class.disabled]="disabled">{{displayValue || empty}}</span>
    <span *ngIf="readOnly">{{displayValue || empty}}</span>

    <mat-form-field class="edit-input" *ngIf="editing && !readOnly">
    <input [disabled]="disabled" #inputElement matInput type="text" [(ngModel)]="value" (blur)="onBlur()">
    </mat-form-field>
</div>
