/**
 * Software
 * Software is wrapper for single orderable or downloadable item
 * Revisions contains different revisions of the same software item
 * KBF (Kemppi binary format) file or ZIP archive of multiple KBF files.
 */


export interface ISoftwareWithNullableSoftwareType extends Omit<ISoftware, 'data'> {
    data: Omit<ISoftware['data'], 'softwareType'> & { softwareType: SoftwareType | null };
}
export interface ISoftwareResponse extends Omit<ISoftwareWithNullableSoftwareType, 'id'> {
    id: null;
}

export type SoftwareType = 'FIRMWARE' | 'ACTIVATED_FUNCTION' | 'WELDING_PROGRAM' | 'PROGRAM_PACK' | 'FIRMWARE_PACK' | 'FACTORY_INSTALL_PACK' | 'FEATURE_PACK';
export interface ISoftware {
    /**
     * Unique identifier
     * [uuid]
     */
    id: string;

    /**
     * Data of software item
     * This part is updated on PUT
     */
    data: {
        /**
         * FIRMWARE             Single firmware update kbf
         * FIRMWARE_PACK        Multiple firmware files
         * ACTIVATED_FUNCTION   Single activated / wise function
         * FEATURE_PACK         Multiple activated / wise functions
         * WELDING_PROGRAM      Single welding program / curve
         * PROGRAM_PACK         Multiple welding programs
         * FACTORY_INSTALL_PACK Multiple firmwares, activations or welding programs for factory preinstalling
         */
        softwareType: SoftwareType

        /**
         * Suitable product family
         * [systemCode]
         */
        productFamily: IProductFamily;

        /**
         * Compatibility for product family sub system
         */
        compatibility?: Array<string>;

        /**
         * Software name
         */
        name: string;

        /**
         * Public description
         */
        description?: string;

        /**
         * Product number
         * Same as ERP PartNumber
         * (Same as Datastore Ordering code)
         * Not used for firmwares
         */
        productNumber?: string;

        /**
         * Product name
         */
        productName?: string;

        /**
         * SW-code
         * Used for firmwares or factory install packs
         */
        swCode?: string;

        /**
         * Revisions of software
         */
        revisions: Array<ISoftwareRevision>;

        /**
         * If set to private restrictions will take effect.
         */
        private?: boolean;

        /**
         * When software is set to private this takes effect.
         * Downloading allowed only for same company.
         */
        restrictedCompany?: Array<string>;

        /**
         * When software is set to private this takes effect.
         * Downloading allowed only for same partnerId.
         */
        restrictedPartnerId?: Array<string>;
    };

    /**
     * Schema info
     */
    schema: SchemaInfo;

    /**
     * Version info
     */
    version: VersionInfo;

    /**
     * Deleted item?
     */
    deleted?: boolean;
}

export interface ISoftwareRevision {
    /**
     * Revision unique identifier
     * Same as Revision number
     */
    id: string;

    /**
     * Revision number
     * Running numbering from 1, 2, 3...
     */
    revisionNumber: number;

    /**
     * Version number
     * Format: 1.00.00.0
     */
    versionNumber: string;

    /**
     * Revision name
     * X5 1.00 PS1
     */
    name?: string;

    /**
     * Revision description
     */
    description?: string;

    /**
     * Mark that represents one of software revisions to be the latest.
     * Shown in Kemppi Connect UI.
     * Never set private restricted software to be the latest official.
     */
    latestOfficial?: boolean;

    /**
     * If set to private restrictions will take effect.
     */
    private?: boolean;

    /**
     * When software is set to private this takes effect.
     * Downloading allowed only for same company.
     */
    restrictedCompany?: Array<string>;

    /**
     * When software is set to private this takes effect.
     * Downloading allowed only for same partnerId.
     */
    restrictedPartnerId?: Array<string>;

    /**
     * File information
     */
    file: ISoftwareFileInfo;

    /**
     * Included softwares
     * Used in softwareType = PROGRAM_PACK / FEATURE_PACK
     */
    includedSoftwares?: Array<IIncludedSoftware>;

    /**
     * File Id
     * ACTIVATED_FUNCTION / WELDING_PROGRAM
     * Readable from KBF
     * 2693533738 = a08c:102a
     */
    fileId?: number;

    /**
     * File Id
     * In hex format 'a08c:102a'
     */
    fileIdHex?: string;

    /**
     * Function Id
     * ACTIVATED_FUNCTION
     * Readable from KBF
     */
    functionId?: number;

    /**
     * Curve Type
     * WELDING_PROGRAM
     * Readable from KBF
     * 41100 = a08c (fileId first part)
     */
    curveType?: number;

    /**
     * Curve type
     * In hex format 'a08c'
     */
    curveTypeHex?: string;

    /**
     * Curve Id
     * WELDING_PROGRAM
     * Readable from KBF
     * 4138 = 102a (fileId second part)
     */
    curveId?: number;

    /**
     * Curve Id
     * In hex format 'a08c'
     */
    curveIdHex?: string;

    /**
     * Revision inactive? If true hide from available revisions list.
     */
    inactive?: boolean;

    /**
     * Manifest of firmware package
     */
    manifest?: any;
}

export interface ISoftwareFileInfo {
    /**
     * Filename
     */
    filename: string;

    /**
     * Full file path
     */
    filepath: string;

    /**
     * Content type
     */
    contentType: string;

    /**
     * Modified timestamp
     */
    lastModifiedDate?: string;
}

export interface IIncludedSoftware {
    /**
     * Software name
     */
    name: string;

    /**
     * Product number
     * Same as ERP PartNumber
     * (Same as Datastore Ordering code)
     * Not used for firmwares
     */
    productNumber: string;

    /**
     * Filename
     */
    filename: string;

    /**
     * Software Unique identifier
     * [uuid]
     */
    softwareId: string;

    /**
     * Revision number
     */
    revisionId: string;

    /**
     * Version number
     * Format: 1.00.00.0
     */
    versionNumber: string;
}

export interface SchemaInfo {
    type: string;
    version: string;
}

export interface VersionInfo {
    created?: {
        timestamp: string;
        user: {
            id: string;
            name: string;
            company?: string;
            partnerId?: string;
            email?: string;
        };
    };
    modified?: {
        timestamp: string;
        user: {
            id: string;
            name: string;
            company?: string;
            partnerId?: string;
            email?: string;
        };
    };
}

/**
 * Available and supported software types
 */
export const SoftwareTypes: Array<string> = [
    'FIRMWARE',
    'ACTIVATED_FUNCTION',
    'WELDING_PROGRAM',
    'PROGRAM_PACK',
    'FIRMWARE_PACK',
    'FACTORY_INSTALL_PACK',
    'FEATURE_PACK'
];

export const SoftwareTypeNames = {
    'FIRMWARE': 'Firmware',
    'ACTIVATED_FUNCTION': 'Activated function',
    'WELDING_PROGRAM': 'Welding program',
    'PROGRAM_PACK': 'Program pack',
    'FIRMWARE_PACK': 'Firmware pack',
    'FACTORY_INSTALL_PACK': 'Factory install pack',
    'FEATURE_PACK': 'Feature pack'
};

/**
 * Available and supported product families
 * systemCode
 */
export const ProductFamilies = [
    'C5TIGWELDER',
    'MASTERMIG',
    'X5MIGWELDER',
    'X8MIGWELDER',
    'AXMIGWELDER',
    'DCM',
    'MINARCTIG',
    'MINARCTIGDC',
    'X3MIGWELDER',
    'X3GFASTGOUGE'
] as const;
export type IProductFamily = typeof ProductFamilies[number]; 
export const ProductFamilyNames: Record<IProductFamily, string> = {
    'C5TIGWELDER': 'C5 TIG Welder',
    'MASTERMIG': 'Master M',
    'X5MIGWELDER': 'X5 FastMig',
    'X8MIGWELDER': 'X8 MIG Welder',
    'AXMIGWELDER': 'AX MIG Welder',
    'DCM': 'DCM',
    'MINARCTIG': 'Minarc T 223 ACDC',
    // Apparently there is an DC variant for the machine
    'MINARCTIGDC': 'Minarc T 223 DC',
    'X3MIGWELDER': 'X3 FastMig',
    'X3GFASTGOUGE': 'X3G FASTGOUGE'
};

export const ProductNames = {
    'MSM205GM': 'MASTER M 205 GM',
    'MSM2323GM': 'MASTER M 323 GM',
    'MSM353G': 'MASTER M 353 G',
    'MSM353GM': 'MASTER M 353 GM',
    'MSM355G': 'MASTER M 355 G',
    'MSM355GM': 'MASTER M 355 GM',
    'MSM358G': 'MASTER M 358 G',
    'MSM358GM': 'MASTER M 358 GM',
    'X5110400000': 'X5 POWER SOURCE 400',
    'X5110400010': 'X5 POWER SOURCE 400 (WP)',
    'X5110500000': 'X5 POWER SOURCE 500',
    'X5110500010': 'X5 POWER SOURCE 500 (WP)',
    'X5130400000': 'X5 POWER SOURCE 400 PULSE',
    'X5130400010': 'X5 POWER SOURCE 400 PULSE (WP)',
    'X5100400000': 'X5 POWER SOURCE 400 PULSE+',
    'X5100400010': 'X5 POWER SOURCE 400 PULSE+ (WP)',
    'X5130500000': 'X5 POWER SOURCE 500 PULSE',
    'X5130500010': 'X5 POWER SOURCE 500 PULSE (WP)',
    'X5100500000': 'X5 POWER SOURCE 500 PULSE+',
    'X5100500010': 'X5 POWER SOURCE 500 PULSE+ (WP)',
    'X5100400100': 'X5 POWER SOURCE 400MV+',
    'X5100400110': 'X5 POWER SOURCE 400MV+ (WP)',
}


export const ProductCodes: Partial<Record<IProductFamily, string>> = {
    'MASTERMIG': 'C5790',
    'X5MIGWELDER': 'C5690',
    'AXMIGWELDER': 'C5890'
}

export const PackageProductDescription: Partial<Record<IProductFamily, string>> = {
    'MASTERMIG': 'Master M Software Package',
    'X5MIGWELDER': 'X5 Software Package',
    'AXMIGWELDER': 'AX Software Package'
}

interface SoftwarePackage {
    family: IProductFamily;
    name: string;
    desc: string;
    productCode: string;
}

export const EnabledSoftwarePackages: Partial<SoftwarePackage>[] = [
    {
        family: 'X5MIGWELDER',
        name: ProductFamilyNames['X5MIGWELDER'],
        desc: PackageProductDescription['X5MIGWELDER'],
        productCode: ProductCodes['X5MIGWELDER']
    },
    {
        family: 'MASTERMIG',
        name: ProductFamilyNames['MASTERMIG'],
        desc: PackageProductDescription['MASTERMIG'],
        productCode: ProductCodes['MASTERMIG']
    },
    {
        family: 'AXMIGWELDER',
        name: ProductFamilyNames['AXMIGWELDER'],
        desc: PackageProductDescription['AXMIGWELDER'],
        productCode: ProductCodes['AXMIGWELDER']
    }
]
/**
 * Compatibility of product families sub system
 */
export const Compatibility: Partial<ICompatibility> = {
    'MASTERMIG': {
        subSystems: [
            '205',
            '323',
            '353',
            '355',
            '358'
        ],
        productCodes: {
            '205': [
                'MSM205GM',
                'MSM205GMAU'
            ],
            '323': [
                'MSM323GM',
                'MSM323GMC1'
            ],
            '353': [
                'MSM353G',
                'MSM353GM',
                'MSM353GC1',
                'MSM353GC2'
            ],
            '355': [
                'MSM355G',
                'MSM355GM',
                'MSM355GC1'
            ],
            '358': [
                'MSM358G',
                'MSM358GM',
                'MSM358GPR'
            ]
        }
    },
    'X5MIGWELDER': {
        subSystems: [
            'SYNERGIC',
            'PULSE',
            'PULSE+'
        ],
        productCodes: {
            // 1-MIG
            'SYNERGIC': [
                'X5110400000', // X5 POWER SOURCE 400
                'X5110400010', // X5 POWER SOURCE 400 (WP)
                'X5110500000', // X5 POWER SOURCE 500
                'X5110500010', // X5 POWER SOURCE 500 (WP)
            ],
            // P-MIG
            'PULSE': [
                'X5130400000', // X5 POWER SOURCE 400 PULSE
                'X5130400010', // X5 POWER SOURCE 400 PULSE (WP)
                'X5100400000', // X5 POWER SOURCE 400 PULSE+
                'X5100400010', // X5 POWER SOURCE 400 PULSE+ (WP)
                'X5130500000', // X5 POWER SOURCE 500 PULSE
                'X5130500010', // X5 POWER SOURCE 500 PULSE (WP)
                'X5100500000', // X5 POWER SOURCE 500 PULSE+
                'X5100500010', // X5 POWER SOURCE 500 PULSE+ (WP)
                'X5100400100', // X5 POWER SOURCE 400MV+
                'X5100400110', // X5 POWER SOURCE 400MV+ (WP)
            ],
            // PULSE+
            'PULSE+': [
                'X5100400000', // X5 POWER SOURCE 400 PULSE+
                'X5100400010', // X5 POWER SOURCE 400 PULSE+ (WP)
                'X5100500000', // X5 POWER SOURCE 500 PULSE+
                'X5100500010', // X5 POWER SOURCE 500 PULSE+ (WP)
                'X5100400100', // X5 POWER SOURCE 400MV+
                'X5100400110', // X5 POWER SOURCE 400MV+ (WP)
            ]
        }
    },
    'AXMIGWELDER': { // this is a copy of X5MIGWELDER object
        subSystems: [
            'SYNERGIC',
            'PULSE',
            'PULSE+'
        ],
        productCodes: {
            // 1-MIG
            'SYNERGIC': [
                'X5110400000', // X5 POWER SOURCE 400
                'X5110400010', // X5 POWER SOURCE 400 (WP)
                'X5110500000', // X5 POWER SOURCE 500
                'X5110500010', // X5 POWER SOURCE 500 (WP)
            ],
            // P-MIG
            'PULSE': [
                'X5130400000', // X5 POWER SOURCE 400 PULSE
                'X5130400010', // X5 POWER SOURCE 400 PULSE (WP)
                'X5100400000', // X5 POWER SOURCE 400 PULSE+
                'X5100400010', // X5 POWER SOURCE 400 PULSE+ (WP)
                'X5130500000', // X5 POWER SOURCE 500 PULSE
                'X5130500010', // X5 POWER SOURCE 500 PULSE (WP)
                'X5100500000', // X5 POWER SOURCE 500 PULSE+
                'X5100500010', // X5 POWER SOURCE 500 PULSE+ (WP)
                'X5100400100', // X5 POWER SOURCE 400MV+
                'X5100400110', // X5 POWER SOURCE 400MV+ (WP)
            ],
            // PULSE+
            'PULSE+': [
                'X5100400000', // X5 POWER SOURCE 400 PULSE+
                'X5100400010', // X5 POWER SOURCE 400 PULSE+ (WP)
                'X5100500000', // X5 POWER SOURCE 500 PULSE+
                'X5100500010', // X5 POWER SOURCE 500 PULSE+ (WP)
                'X5100400100', // X5 POWER SOURCE 400MV+
                'X5100400110', // X5 POWER SOURCE 400MV+ (WP)
            ]
        }
    },
    'X3MIGWELDER': {
        subSystems: [
            'SYNERGIC',
            'PULSE',
        ],
        productCodes: {
            // TODO: Find out ALL product codes for synergic / pulse
            // 1-MIG
            'SYNERGIC': [
                'X3S420G'
            ],
            // P-MIG
            'PULSE': [
                'X3P450W'
            ],
        }
    }
};
export type ICompatibility = Record<IProductFamily, {
    subSystems: Array<string>;
    productCodes: Record<string, Array<string>>;
}>

export function getCompatibleProducts(productFamily: IProductFamily, compatibility: Array<string>) {
    let products: Array<string> = [];
    if (!Compatibility[productFamily]) {
        return products;
    }

    // Empty compatibility = all subSystems
    if (!compatibility || compatibility.length === 0) {
        compatibility = Compatibility[productFamily].subSystems;
    }

    for (const subSystem of compatibility) {
        if (Compatibility[productFamily].productCodes[subSystem]) {
            products = products.concat(Compatibility[productFamily].productCodes[subSystem].filter((item) => products.indexOf(item) < 0))
        }
    }
    return products;
}


export function isSoftwareCompatibleForProduct(software: ISoftware, productId: string) {

    const originalId = productId

    // Check productFamily
    let productFamily: IProductFamily | null = null;
    if (productId.startsWith('X51') || productId.startsWith('HAX')) {
        if (software.data.productFamily === 'AXMIGWELDER') {
            productFamily = 'AXMIGWELDER'
        } else {
            productFamily = 'X5MIGWELDER';
        }
    } else if (productId.startsWith('MSM')) {
        productFamily = 'MASTERMIG'
    } else if (productId.startsWith('X81')) {
        productFamily = 'X8MIGWELDER'
    }


    if (software.data.productFamily !== productFamily) {
        return false;
    }

    if (software.data.compatibility) {
        const compatibleProducts = getCompatibleProducts(productFamily, software.data.compatibility);

        if (!compatibleProducts.includes(productId) && !originalId.startsWith('HAX')) {
            return false;
        }
    }
    return true;
}