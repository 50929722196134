import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { KsFormSetModule } from '../../shared/ks-formset/ks-formset.module';
import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginSelectPageComponent } from './components/login-select-page/login-select-page.component';
import { CallbackComponent } from './components/callback/callback.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatChipsModule,
        MatDatepickerModule, MatTooltipModule, MatProgressSpinnerModule,
        MatMomentDateModule,

        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsFormSetModule,
        KsNavigationModule
    ],
    declarations: [
        LandingPageComponent,
        LoginPageComponent,
        LoginSelectPageComponent,
        CallbackComponent
    ],
    exports: [
        LandingPageComponent,
        LoginPageComponent,
        LoginSelectPageComponent,
        CallbackComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BaseModule {
}
