<div class="main flex-column">
    <div class="page-container full flex-column align-center gap-20">
        <h1>Software Hub</h1>

        <div class="flex-column">
            <!--
            <button mat-button color="accent" routerLinkActive="active" routerLink="/mobile-devices">Link your Kemppi Connect tool to service</button>
            -->
            <!--
            <a (click)="getToken()">
                <h3>Link your Kemppi Connect tool to service</h3>
            </a>
            <ng-container *ngIf="activationToken">
                <div>
                    Get it from google play.
                    <img src="assets/images/google-play.png" style="width: 130px; height: 50px">
                </div>
                <div>Open Kemppi Connect tool and read following QR-code with the application</div>
                <div>
                    <ks-barcode [(ngModel)]="activationToken" [qrCodeSize]="1.4" type="2D"></ks-barcode>
                </div>
            </ng-container>
            -->
        </div>

        <div class="flex-column">
            <div *ngIf="allowedModules" class="flex-row flex-wrap justify-center">
                <button mat-button *ngIf="allowedModules['licenses']" routerLinkActive="active" routerLink="/software" class="module-select">
                    <div class="flex-column">
                        <div>
                            <mat-icon class="kemppi-symbols">O</mat-icon>
                        </div>
                        <div>{{ 'menu.software' | translate }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules['devices']" routerLinkActive="active" routerLink="/mobile-devices" class="module-select">
                    <div class="flex-column">
                        <div>
                            <mat-icon class="kemppi-symbols">Ē</mat-icon>
                        </div>
                        <div>{{ 'menu.mobile_devices' | translate }}</div>
                    </div>
                </button>
            </div>
            <div *ngIf="allowedModules" class="flex-row flex-wrap justify-center">
                <button mat-button *ngIf="allowedModules['orders']" routerLinkActive="active" routerLink="/order" class="module-select">
                    <div class="flex-column">
                        <div>
                            <mat-icon class="kemppi-symbols">É</mat-icon>
                        </div>
                        <div>{{ 'menu.order' | translate }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules['softwares']" routerLinkActive="active" routerLink="/maintain-software" class="module-select">
                    <div class="flex-column">
                        <div>
                            <mat-icon class="kemppi-symbols">s</mat-icon>
                        </div>
                        <div>{{ 'menu.maintain_software' | translate }}</div>
                    </div>
                </button>
            </div>

            <div *ngIf="!allowedModules || (!allowedModules['licenses'])">
                {{ 'landing.no_permission_to_any_module' | translate }}
            </div>
        </div>

        <br>
        <br>

    </div>
    <div class="footer full flex-column align-center">
        <div class="flex-row flex-wrap justify-space-between">
            <div></div>
            <div class="footer-text">
                <h2>Software Hub</h2>
                <p>{{ 'landing.swhub_description' | translate }}</p>
            </div>
            <div></div>
            <div class="footer-text">
                <h3>{{ 'landing.quick_links' | translate }}</h3>
                <h3><a href="https://my.kemppi.com">My Kemppi portal</a></h3>
                <h3><a href="https://www.kemppi.com/en-US/support/find-support">Kemppi Support</a></h3>
            </div>
            <div></div>
        </div>
        <div class="footer-text flex-row justify-center">Copyright &copy; 2023 Kemppi Oy.</div>
    </div>

</div>
