import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FileUploadModule } from 'ng2-file-upload';

// Angular
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';

// Modules
import { BaseModule } from './modules/base/base.module';
import { KsFormSetModule } from './shared/ks-formset/ks-formset.module';
import { KsNavigationModule } from './shared/ks-navigation/ks-navigation.module';
import { DevicesModule } from './modules/devices/devices.module';
import { LicensesModule } from './modules/licenses/licenses.module';
import { SoftwaresModule } from './modules/softwares/softwares.module';
import { WarCustomModule } from './shared/war-custom/war-custom.module';
import { DialogsModule } from './shared/dialogs/dialogs.module';

// Services
// import { DevicesService } from './services/devices.service';
import { AppConfigService } from './services/app-config.service';
import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { LicensesService } from './services/licenses.service';
import { LocaleService } from 'src/app/services/locale.service';
import { LoginService } from './services/login.service';
import { MachineryService } from './services/machinery.service';
import { MobileDevicesService } from './services/mobile-devices.service';
import { SoftwaresService } from './services/softwares.service';
import { StateService } from './services/state.service';

// Translate
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
import localeFi from '@angular/common/locales/fi';

registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeFi, 'fi');

export function createTranslateLoader(http: HttpClient) {
    const date = new Date().getTime(); // Use time as suffix to force reload translation file
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?' + date);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule,
        MatMenuModule, MatCardModule, MatTooltipModule, MatSelectModule, MatCheckboxModule, MatButtonToggleModule, MatDialogModule,
        MatSnackBarModule, MatDatepickerModule, MatAutocompleteModule,
        MatMomentDateModule,
        FormsModule, ReactiveFormsModule,
        // FileUploadModule,

        BaseModule,
        KsFormSetModule,
        KsNavigationModule,
        WarCustomModule,
        DialogsModule,

        DevicesModule,
        LicensesModule,
        SoftwaresModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        // DevicesService,
        AppConfigService,
        AuthService,
        LicensesService,
        LocaleService,
        LoginService,
        MachineryService,
        MobileDevicesService,
        SoftwaresService,
        StateService,

        // Global input mat-form-filed mat-label option to float always without fancy bouncing
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },

        CanDeactivateGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
