<div *ngIf="order" class="main">
    <!-- NORMAL -->
    <div *ngIf="!advancedMode" class="list-container">
        <div class="full main-header flex-row gap-20">
            <mat-icon class="kemppi-symbols">É</mat-icon>
            <div class="flex-column">
                <h2>{{ 'orders.orders_topic' | translate }}</h2>
                <div>{{ 'orders.orders_description' | translate }}</div>
            </div>
        </div>

        <div class="full container flex-row">
            <div class="flex-column">
                <h2 class="mat-subhead">{{ 'orders.general_information' | translate }}</h2>
                <div class="flex-row gap-20 align-center">
                    <mat-form-field style="width:250px" readonly="true">
                        <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.partnerName" readonly="true">
                    </mat-form-field>
                    <mat-form-field [attr.readonly]="authUserScope !== 'global'">
                        <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.partnerId" (change)="updateCustomerName()" readonly="{{ authUserScope !== 'global' }}">
                    </mat-form-field>
                    <mat-form-field *ngIf="authUserScope === 'global'">
                        <mat-label>{{ 'common.company' | translate }}</mat-label>
                        <mat-select [(value)]="order.company" (selectionChange)="updateCustomerName()">
                            <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} -
                                {{ company.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="authUserScope !== 'global'" readonly="true">
                        <mat-label>{{ 'common.company' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.company" (change)="updateCustomerName()" readonly="true">
                    </mat-form-field>
                    <div *ngIf="loading.customerInfo" class="flex-row">
                        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                    </div>
                </div>
                <div class="flex-row gap-20">
                    <mat-form-field readonly="true">
                        <mat-label>{{ 'common.order_number' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.orderNumber" readonly="true">
                    </mat-form-field>
                </div>

                <h2 class="mat-subhead">{{ 'orders.order_lines_and_packages' | translate }}</h2>
                <div *ngFor="let ol of order.orderLines; let i = index" class="flex-column">
                    <div *ngIf="!ol.isSoftware" class="package-topic">
                        <mat-form-field style="width:250px">
                            <mat-label>{{ 'common.package_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="ol.packageName" [disabled]="ol.orderLine !== ol.parentLine" (change)="changePackageName(ol)" maxlength="30" required>
                        </mat-form-field>
                        <mat-form-field style="width:150px;margin-left: 30px;" *ngIf="i === 0">
                            <mat-label>{{ 'common.type' | translate }}</mat-label>
                            <mat-select [(ngModel)]="selectedSoftwarePackageType" (selectionChange)="updateSoftwarePackageType($event?.value)" >
                                <mat-option *ngFor="let packageType of softwarePackageTypes" [value]="packageType">{{ packageType.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex-row gap-20 order-line">
                        <mat-form-field *ngIf="!ol.isSoftware" style="width:100px" readonly="true">
                            <mat-label>{{ 'common.part' | translate }}</mat-label>
                            <input type="text" matInput [(ngModel)]="ol.productNumber" readonly="true">
                        </mat-form-field>
                        <mat-form-field *ngIf="ol.isSoftware" style="width:100px">
                            <mat-label>{{ 'common.part' | translate }}</mat-label>
                            <input type="text" matInput placeholder="{{ 'common.select' | translate }}" [formControl]="productsFormControl[i]" [matAutocomplete]="auto" [(ngModel)]="ol.productNumber" (change)="updateProductDescription(i)">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateProductDescription(i)" style="width: 700px;">
                                <mat-option *ngFor="let product of filterProducts(products, ol.productNumber)" [value]="product.productNumber" class="product-option">
                                    <div style="width: 120px">{{ product.productNumber }}</div>
                                    <div style="width: 400px"><small>{{ product.description }}</small></div>
                                    <div style="width: 180px"><small><i>{{ product.compatibility }}</i></small></div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field style="width:250px" readonly="true">
                            <mat-label>{{ 'common.description' | translate }}</mat-label>
                            <input matInput [(ngModel)]="ol.description" readonly="true">
                        </mat-form-field>
                        <mat-form-field style="width:60px">
                            <mat-label>{{ 'common.quantity' | translate }}</mat-label>
                            <input matInput [(ngModel)]="ol.quantity" type="number" [disabled]="ol.orderLine !== ol.parentLine" (change)="changeQuantity(ol)">
                        </mat-form-field>

                        <button mat-icon-button mat-raised-button color="warn" (click)="removeOrderLine(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </div>
                <div class="flex-row gap-20">
                    <div>
                        <button mat-raised-button color="accent" (click)="addOrderLine(order.orderLines[order.orderLines.length - 1].parentLine)">
                            <mat-icon>add</mat-icon> {{ 'orders.add_software' | translate }}
                        </button>
                    </div>
                </div>
                <div class="flex-row gap-20">
                    <div>
                        <button mat-button color="primary" (click)="addOrderLine()">
                            <mat-icon>add</mat-icon> {{ 'orders.add_new_package' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ADVANCED -->
    <div *ngIf="advancedMode" class="list-container">
        <div class="full main-header flex-row gap-20">
            <mat-icon class="kemppi-symbols">É</mat-icon>
            <div class="flex-column">
                <h2>{{ 'orders.orders_topic' | translate }}</h2>
                <div>{{ 'orders.orders_description' | translate }}</div>
            </div>
        </div>

        <div class="full container flex-row">
            <div class="flex-column">
                <h2 class="mat-subhead">{{ 'orders.general_information' | translate }}</h2>
                <div class="flex-row gap-20">
                    <mat-form-field style="width:250px">
                        <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.partnerName">
                    </mat-form-field>
                    <mat-form-field [attr.readonly]="authUserScope !== 'global'">
                        <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.partnerId" (change)="updateCustomerName()" [attr.readonly]="authUserScope !== 'global'">
                    </mat-form-field>
                    <mat-form-field [attr.readonly]="authUserScope !== 'global'">
                        <mat-label>{{ 'common.company' | translate }}</mat-label>
                        <mat-select [(value)]="order.company" (selectionChange)="updateCustomerName()" [attr.readonly]="authUserScope !== 'global'">
                            <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} -
                                {{ company.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="loading.customerInfo" class="flex-row">
                        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                    </div>
                </div>
                <div class="flex-row gap-20">
                    <mat-form-field>
                        <mat-label>{{ 'common.order_number' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.orderNumber">
                        <mat-hint>[ 'SW' + date + time ]</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'common.date' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.orderDate">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'common.source' | translate }}</mat-label>
                        <mat-select [(value)]="order.referenceSource">
                            <mat-option *ngFor="let source of sourceApplications" [value]="source.key">{{ source.key}} - {{ source.description }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'common.reference' | translate }}</mat-label>
                        <input matInput [(ngModel)]="order.referenceNumber">
                    </mat-form-field>
                </div>

                <h2 class="mat-subhead">{{ 'orders.order_lines_and_packages' | translate }}</h2>
                <div *ngFor="let ol of order.orderLines; let i = index" class="flex-column gap-20">
                    <div *ngIf="!ol.isSoftware" class="package-topic weak">SALES KIT: {{ ol.description }}</div>
                    <div class="flex-row gap-20 order-line align-center">
                        <mat-form-field style="width:60px">
                            <mat-label>{{ 'orders.line_no' | translate }}</mat-label>
                            <input matInput [(ngModel)]="ol.orderLine">
                        </mat-form-field>
                        <mat-form-field style="width:60px">
                            <mat-label>{{ 'orders.parent' | translate }}</mat-label>
                            <mat-select [(value)]="ol.parentLine">
                                <mat-option *ngFor="let orderLine of order.orderLines" [value]="orderLine.orderLine">{{ orderLine.orderLine }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox [(ngModel)]="ol.isSoftware">{{ 'common.software' | translate }}</mat-checkbox>
                        <mat-checkbox [(ngModel)]="ol.isVerified">{{ 'common.verified' | translate }}</mat-checkbox>

                        <mat-form-field style="width:100px">
                            <mat-label>{{ 'common.part' | translate }}</mat-label>
                            <input type="text" matInput [formControl]="productsFormControl[i]" [matAutocomplete]="auto" [(ngModel)]="ol.productNumber">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateProductDescription(i)">
                                <!-- <mat-option value="C5690"><b style="width:200px">C5690</b> - X5 Software Package</mat-option>
                                <mat-option value="C5600"><b style="width:200px">C5600</b> - X5 Package</mat-option> -->
                                <mat-option *ngFor="let product of products" [value]="product.productNumber" class="product-option">
                                    <div style="width: 120px">{{ product.productNumber }}</div>
                                    <div style="width: 400px"><small>{{ product.description }}</small></div>
                                    <div style="width: 180px"><small><i>{{ product.compatibility }}</i></small></div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <!--
                        <mat-form-field style="width:110px">
                            <mat-label>Product number</mat-label>
                            <mat-select [(value)]="ol.productNumber" (selectionChange)="updateProductDescription(i)">
                                <mat-option *ngFor="let product of products" [value]="product.productNumber">{{ product.productNumber }} - {{ product.description }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        -->
                        <mat-form-field>
                            <mat-label>{{ 'common.description' | translate }}</mat-label>
                            <input matInput [(ngModel)]="ol.description">
                        </mat-form-field>
                        <mat-form-field style="width:60px">
                            <mat-label>{{ 'common.quantity' | translate }}</mat-label>
                            <input matInput [(ngModel)]="ol.quantity" type="number" (change)="changeQuantity(ol)">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'common.package_name' | translate }} (SWHub_c)</mat-label>
                            <input matInput [(ngModel)]="ol.packageName" (change)="changePackageName(ol)" maxlength="30">
                        </mat-form-field>

                        <button mat-icon-button mat-raised-button color="warn" (click)="removeOrderLine(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </div>
                <div class="flex-row gap-20">
                    <div>
                        <button mat-raised-button color="accent" (click)="addOrderLine(order.orderLines[order.orderLines.length - 1].parentLine)">
                            <mat-icon>add</mat-icon> {{ 'orders.add_order_line' | translate }}
                        </button>
                    </div>
                </div>
                <div class="flex-row gap-20">
                    <div>
                        <button mat-button color="primary" (click)="addOrderLine()">
                            <mat-icon>add</mat-icon> {{ 'orders.add_new_sales_kit' | translate }}
                        </button>
                    </div>
                </div>

                <div class="flex-column weak">
                    <i>Parent orderline should be the saleskit / software package wrapper</i>
                    <i>Child orderlines that shares the parent will be in same package, and also will be installed together in Kemppi Connect app.</i>
                    <i>Software = Part is software and licenses will be created for this orderline</i>
                    <i>Verified = Orderline FirmRealease is ok, created licenses will be active</i>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="advancedMode" class="flex-column align-center">
        <br>
        <div class="flex-row">
            <h2 class="mat-subhead">Normal process from order to software license:</h2>
        </div>
        <div class="flex-row">
            <div class="block main-block">
                <mat-icon>account_circle</mat-icon><br>
                User
            </div>
            <div>
                <mat-icon>arrow_forward</mat-icon>
            </div>
            <div class="block main-block">
                <div class="align-center">
                    <mat-icon>local_grocery_store</mat-icon><b>User places order</b><br>
                </div>
                Web store (Datastore / eOrdering)<br>
                (Source application)
            </div>
            <div>
                <mat-icon>arrow_forward</mat-icon>
            </div>
            <div class="block main-block">
                <div class="align-center">
                    <mat-icon>storage</mat-icon><b>Order is created</b><br>
                </div>
                ERP (Epicor)<br>
                <div class="flex-row">
                    <div class="block">REST API</div>
                    <div class="block">Trigger</div>
                    <div class="block">SQL database</div>
                </div>
            </div>
            <div>
                <mat-icon>arrow_forward</mat-icon>
            </div>
            <div class="block main-block">
                <div class="align-center">
                    <mat-icon>sync</mat-icon><b>Fetch order</b><br>
                </div>
                Node process
            </div>
            <div>
                <mat-icon>arrow_forward</mat-icon>
            </div>
            <div class="block main-block">
                <div class="align-center">
                    <mat-icon>storage</mat-icon><b>Generate licenses</b><br>
                </div>
                SWHub / Machinery<br>
                <div class="flex-row">
                    <div class="block">REST API</div>
                    <div class="block">License storage</div>
                </div>
            </div>
        </div>
        <div class="flex-row">
            <i>Creating and sending order on this form will skip this whole process and send custom order straight to SWHub / Machinery</i>
        </div>
        <br>
    </div>

    <ks-actionbar [hasCancel]="false" [hasSave]="false">
        <div class="flex-row full justify-space-between">
    
            <div class="align-center">
                <ng-container *ngIf="authService.isAllowedTo('licenses.write', 'global')">
                    <button mat-button color="warn" (click)="showKoyTools = !showKoyTools">
                        <mat-icon class="kemppi-symbols">s</mat-icon>
                    </button>
    
                    <ng-container *ngIf="showKoyTools">
                        <mat-checkbox [(ngModel)]="advancedMode">
                            <div>Advanced mode <small><i>(EKKJ)</i></small></div>
                        </mat-checkbox>
                    </ng-container>
                </ng-container>
            </div>

            <div class="align-center">
                <button mat-raised-button color="accent" (click)="placeOrder()" [disabled]="loading.placeOrder">
                    <div *ngIf="!loading.placeOrder">{{ 'orders.submit_order' | translate }}</div>
                    <div *ngIf="loading.placeOrder" class="flex-row align-center">
                        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                        &nbsp;{{ 'common.processing' | translate }}
                    </div>
                </button>
            </div>

            <div></div>
        </div>
    </ks-actionbar>
</div>