import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-value-dialog',
  templateUrl: 'value-dialog.component.html',
  styles: []
})
export class ValueDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ValueDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

}
