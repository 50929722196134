<div class="main">
    <mat-tab-group>
        <mat-tab label="{{ 'software.software_topic' | translate }}">
            <div class="list-container">
                <div class="full main-header flex-row gap-20">
                    <mat-icon class="kemppi-symbols">O</mat-icon>
                    <div class="flex-column">
                        <h2>{{ 'software.software_topic' | translate }}</h2>
                        <div>{{ 'software.software_description' | translate }}</div>
                    </div>
                </div>

                <div class="full flex-column">
                    <h2 class="mat-subhead">{{ 'common.search' | translate }}</h2>

                    <div class="flex-row flex-wrap gap-20">

                        <form *ngIf="permissions.scope === 'global' || permissions.scope === 'company'">
                            <mat-form-field style="width:300px">
                                <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                                <input type="text" matInput [formControl]="customersFormControl"
                                    [matAutocomplete]="auto" [(ngModel)]="searchPartner" (keyup)="searchCustomers()">
                                <mat-autocomplete #auto="matAutocomplete"
                                    (optionSelected)="changeCustomer($event.option)">
                                    <mat-option *ngFor="let customer of customersList" [value]="customer">
                                        <small style="display:inline-flex; width:250px">{{ customer.name }}</small>
                                        <small style="display:inline-flex; width:10px"></small>
                                        <small style="display:inline-flex; width:40px"><b>{{ customer.company
                                                }}</b></small>
                                        <small style="display:inline-flex; width:80px"><b>{{ customer.custId
                                                }}</b></small>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </form>

                        <mat-form-field *ngIf="permissions.scope === 'global'" style="width:120px">
                            <mat-label>{{ 'common.company' | translate }}</mat-label>
                            <mat-select [(value)]="searchCompany" (selectionChange)="search()">
                                <mat-option value="">{{ 'common.any' | translate }}</mat-option>
                                <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} -
                                    {{ company.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="permissions.scope === 'global' || permissions.scope === 'company'"
                            style="width:120px">
                            <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                            <input matInput [(ngModel)]="searchPartnerId" (change)="search()">
                        </mat-form-field>
                        <mat-form-field style="width:120px">
                            <mat-label>{{ 'common.order_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="searchOrderNumber" (change)="search()">
                        </mat-form-field>
                        <mat-form-field style="width:120px">
                            <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="searchSerialNumber" (change)="searchWithSerial()">
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="searchNotUsed" (change)="search()">{{ 'software.unused_only' |
                            translate }}</mat-checkbox>
                        <mat-checkbox *ngIf="permissions.scope === 'global'" [(ngModel)]="searchNotActive"
                            (change)="search()">{{ 'software.show_inactive' | translate }}</mat-checkbox>
                        <div>
                            <button mat-button color="warn" (click)="resetSearchParams(); search()">{{ 'common.reset' |
                                translate }}</button>
                        </div>
                    </div>
                </div>

                <!-- GROUPED -->
                <h2 class="mat-subhead">{{ 'software.orders_and_software_packages' | translate }}</h2>
                <div class="list-header-row flex-row">
                    <div style="width:40px">
                    </div>
                    <div *ngIf="permissions.scope === 'global'" style="width:50px" class="hide-in-sm">
                        <small>{{ 'common.company' | translate }}</small>
                    </div>
                    <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" style="width:80px"
                        class="hide-in-sm">
                        <small>{{ 'common.partner_id' | translate }}</small>
                    </div>
                    <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" style="width:200px"
                        class="hide-in-sm">
                        <small>{{ 'common.partner_name' | translate }}</small>
                    </div>
                    <div class="flex-grow">
                        <small>{{ 'common.software_packages' | translate }}</small>
                    </div>
                    <div style="width:130px" class="hide-in-sm">
                        <small>{{ 'common.order_number' | translate }}</small>
                    </div>
                    <div style="width:100px">
                        <small>{{ 'common.date' | translate }}</small>
                    </div>
                    <div style="width:100px" class="hide-in-sm">
                        <small>{{ 'common.source' | translate }}</small>
                    </div>
                    <div style="width:120px">
                        <small>{{ 'common.installations_left' | translate }}</small>
                    </div>
                </div>
                <div *ngIf="grouped" class="flex-column">
                    <div *ngFor="let order of grouped; let i = index" class="list-row-wrapper flex-column">
                        <div class="list-row flex-row align-center" [ngClass]="{
                                'NOT-INSTALLED': order.bundlesUsed === 0,
                                'NOT-ACTIVE': order.active === false
                            }" (click)="showOrders[order.id] = !showOrders[order.id]">
                            <div style="width:40px">
                                <mat-icon class="kemppi-symbols" *ngIf="!showOrders[order.id]">÷</mat-icon>
                                <mat-icon class="kemppi-symbols" *ngIf="showOrders[order.id]">ù</mat-icon>
                            </div>
                            <div *ngIf="permissions.scope === 'global'" style="width:50px" class="hide-in-sm">
                                {{order.company}}
                            </div>
                            <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'"
                                style="width:80px" class="hide-in-sm">
                                {{order.partnerId}}
                            </div>
                            <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'"
                                style="width:200px" class="hide-in-sm">
                                <small>{{order.partnerName}}</small>
                            </div>
                            <div class="flex-grow bundle-names">
                                {{ _.join(_.map(order.bundleGroups, 'name'), ', ') }}
                            </div>
                            <div style="width:130px">
                                {{order.orderNumber}}
                            </div>
                            <div style="width:100px" class="hide-in-sm">
                                {{order.orderDate | date:'dd.MM.yyyy'}}
                            </div>
                            <div style="width:100px" class="hide-in-sm">
                                <small>{{order.bundleGroups[0].bundles[0].licenses[0].data.referenceSource}}</small>
                            </div>
                            <div style="width:120px" class="flex-row"
                                [ngClass]="{'NOT-INSTALLED': order.bundlesUsed !== order.bundlesCount}">
                                <div style="width:60px">{{order.bundlesCount - order.bundlesUsed}} /
                                    {{order.bundlesCount}}</div>
                                <small *ngIf="user && user.role && user.role === 'sys_admin'"
                                    class="weak">({{order.licensesCount - order.licensesUsed}} /
                                    {{order.licensesCount}})</small>
                            </div>
                        </div>
                        <ng-container *ngIf="showOrders[order.id]">
                            <div class="attach-note-text weak" *ngIf="order.bundleGroups?.length > 1"> Note: You can attach and download multiple packages to the same machine
                                simultaneously.
                            </div>
                            <div *ngFor="let bundleGroup of order.bundleGroups; let orderBundleGroupsSeq = index"
                                class="bundle flex-column" [ngClass]="{
                                    'NOT-ACTIVE': bundleGroup.active === false
                                }">

                                <div class="flex-row flex-wrap">
                                    <div style="width:560px" class="flex-column">
                                        <!-- Bundle count and name -->
                                        <div class="flex-row">
                                            <div style="width:25px">{{bundleGroup.bundlesCount}}x</div>
                                            <b>{{bundleGroup.name}}</b>
                                        </div>

                                        <!-- Bundle products -->
                                        <div *ngFor="let product of bundleGroup.products" class="flex-row products">
                                            <div style="width:120px">{{product.productNumber}}</div>
                                            <div style="width:440px">{{product.productName}}</div>
                                        </div>
                                    </div>

                                    <!-- BundleIndexes in bundle -->
                                    <div class="flex-grow flex-column">
                                        <div class="flex-row">
                                            <small class="weak">{{ bundleGroup.bundlesCount }} {{ 'common.installations'
                                                | translate }}</small>
                                        </div>
                                        <div class="flex-grow flex-row flex-wrap">
                                            <div *ngFor="let bundle of bundleGroup.bundles" class="flex-row flex-wrap">
                                                <div *ngFor="let license of bundle.licenses; let j = index">
                                                    <div class="flex-row" *ngIf="j === 0">
                                                        <div class="license-bundle flex-column"
                                                            (click)="selectBundleLicenses(order.orderNumber, license.data.bundleId, orderBundleGroupsSeq)"
                                                            [ngClass]="{
                                                                'NOT-INSTALLED': !license.serialNumber,
                                                                'NOT-ACTIVE': license.active === false || license.disabledForSelection,
                                                                'selected': selectedItems[license.id]
                                                            }">
                                                            <button mat-button class="flex-row"
                                                                [disabled]="license.disabledForSelection">
                                                                <small>{{ license.data.bundleIndex }}</small>
                                                                <ng-container
                                                                    *ngIf="!license.serialNumber && !license.data.deviceId">
                                                                    <!--
                                                                    <mat-icon>lock_open</mat-icon>
                                                                    -->
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!license.serialNumber && license.data.deviceId">
                                                                    <mat-icon>lock</mat-icon>
                                                                    <mat-icon class="kemppi-symbols">¼</mat-icon>
                                                                </ng-container>
                                                                <ng-container *ngIf="license.serialNumber">
                                                                    <mat-icon>lock</mat-icon>
                                                                    {{ license.serialNumber }}
                                                                </ng-container>

                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </ng-container>

                    </div>
                </div>
                <div class="flex-row justify-center">
                    <mat-progress-spinner matSuffix *ngIf="loadingOrders" [mode]="'indeterminate'" [color]="'primary'"
                        [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                </div>
                <div class="flex-row justify-center">
                    <button *ngIf="!loadingOrders && grouped && grouped.length === searchSize" mat-button
                        color="primary" (click)="loadMore()">
                        <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'common.load_more' | translate }}
                    </button>
                </div>

                <h2 *ngIf="user && user.role && (user.role === 'koy_user' || user.role === 'sys_admin')"
                    class="mat-subhead">
                    {{ 'common.licenses' | translate }}
                    <button mat-button (click)="showLicenses = !showLicenses">
                        <span *ngIf="showLicenses">{{ 'common.hide' | translate }}</span>
                        <span *ngIf="!showLicenses">{{ 'common.show' | translate }}</span>
                    </button>
                </h2>
                <div *ngIf="showLicenses && user && user.role && (user.role === 'koy_user' || user.role === 'sys_admin')"
                    class="flex-column">
                    <div class="list-header-row flex-row">
                        <div style="width:40px">
                            <mat-checkbox [(ngModel)]="allItemsChecked"
                                (change)="selectAllItems(allItemsChecked)"></mat-checkbox>
                        </div>
                        <div style="width:150px">
                            <small>{{ 'common.id' | translate }}</small>
                        </div>
                        <div style="width:130px">
                            <small>{{ 'common.order_number' | translate }}</small>
                        </div>
                        <div style="width:200px">
                            <small>{{ 'common.software_pack' | translate }}</small>
                        </div>
                        <div style="width:40px">
                            <small>{{ 'common.index' | translate }}</small>
                        </div>
                        <div style="width:140px">
                            <small>{{ 'common.product_number' | translate }}</small>
                        </div>
                        <div class="flex-grow">
                            <small>{{ 'common.product_name' | translate }}</small>
                        </div>
                        <div style="width:120px">
                            <small>{{ 'common.serial_number' | translate }}</small>
                        </div>
                        <div style="width:50px">
                            <small>{{ 'common.device' | translate }}</small>
                        </div>
                    </div>
                    <div class="flex-row justify-center">
                        <mat-progress-spinner matSuffix *ngIf="loadingLicenses" [mode]="'indeterminate'"
                            [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                    </div>
                    <div *ngIf="!loadingLicenses && licenses" class="flex-column">
                        <ng-container *ngFor="let license of licenses">
                            <div *ngIf="license" class="list-row align-center" [ngClass]="{
                                    'NOT-INSTALLED': (!license.serialNumber && !license.deviceId),
                                    'NOT-ACTIVE': license.active === false
                                }" (click)="selectBundleLicenses(license.orderNumber, license.data.bundleId)">
                                <div style="width:40px" class="select-checkbox">
                                    <mat-checkbox [checked]="selectedItems[license.id]"
                                        (click)="$event.preventDefault()">
                                    </mat-checkbox>
                                </div>
                                <div style="width:150px">
                                    <small>{{license.id}}</small>
                                </div>
                                <div style="width:130px">
                                    {{license.orderNumber}}
                                </div>
                                <div style="width:200px">
                                    {{license.data.bundleName}}
                                </div>
                                <div style="width:40px">
                                    {{license.data.bundleIndex}}
                                </div>
                                <div style="width:140px">
                                    <small>{{license.productNumber}}</small>
                                </div>
                                <div class="flex-grow">
                                    <small>{{license.data.productName}}</small>
                                </div>
                                <div style="width:120px">
                                    {{license.serialNumber}}
                                </div>
                                <div style="width:50px">
                                    <mat-icon *ngIf="license.data.deviceId" class="kemppi-symbols">¼</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <ks-actionbar [hasCancel]="false" [hasSave]="false">
                <div class="flex-row full justify-space-between">

                    <div>
                        <ng-container *ngIf="authService.isAllowedTo('licenses.write', 'global')">
                            <button mat-button color="warn" (click)="showKoyTools = !showKoyTools">
                                <mat-icon class="kemppi-symbols">s</mat-icon>
                            </button>

                            <ng-container *ngIf="showKoyTools && anyItemSelected">
                                <button mat-button color="accent" (click)="changeSelectedLicensesActivity(true)">
                                    <mat-icon>done</mat-icon> {{ 'common.activate' | translate }}
                                </button>
                                <button mat-button color="warn" (click)="changeSelectedLicensesActivity(false)">
                                    <mat-icon>clear</mat-icon> {{ 'common.deactivate' | translate }}
                                </button>
                                <button mat-button color="accent" (click)="openAttachDialog('device')">
                                    <mat-icon class="kemppi-symbols">¼</mat-icon> {{ 'software.attach_to_device' |
                                    translate }}
                                </button>
                                <button mat-button mat-raised-button color="warn"
                                    *ngIf="user && user.role && user.role === 'sys_admin'"
                                    (click)="openDeleteConfirmDialog()">
                                    <mat-icon>delete</mat-icon> {{ 'common.delete' | translate }}
                                </button>
                            </ng-container>
                            <ng-container *ngIf="showKoyTools && !anyItemSelected">
                                <small class="weak"><i>{{ 'software.select_software_package' | translate }}</i></small>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div *ngIf="anyItemSelected" class="align-center">
                        <button mat-button mat-raised-button color="accent" (click)="openAttachDialog('serial')"
                            [disabled]="!reattachLicenseAllowed || loadingAttachment">
                            <div *ngIf="!loadingAttachment" class="flex-row align-center">
                                <mat-icon>lock</mat-icon>
                                &nbsp;{{ 'software.assign' | translate }} (USB)
                            </div>
                            <div *ngIf="loadingAttachment" class="flex-row align-center">
                                <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20"
                                    [strokeWidth]="2"></mat-progress-spinner>
                                &nbsp;{{ 'common.processing' | translate }}
                            </div>
                        </button>
                        <button mat-button mat-raised-button color="accent" (click)="downloadOffline()"
                            [disabled]="!offlineDownloadAllowed || loadingDownload">
                            <div *ngIf="!loadingDownload" class="flex-row align-center">
                                <mat-icon>cloud_download</mat-icon>
                                &nbsp;{{ 'common.download' | translate }} (USB)
                            </div>
                            <div *ngIf="loadingDownload" class="flex-row align-center">
                                <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20"
                                    [strokeWidth]="2"></mat-progress-spinner>
                                &nbsp;{{ 'common.processing' | translate }}
                            </div>
                        </button>
                        <mat-checkbox [(ngModel)]="includeForcedVRD" class="forced-vrd"
                            [ngClass]="{'active': includeForcedVRD}" [disabled]="!offlineDownloadAllowed">
                            <div>{{ 'common.include' | translate }}</div>
                            <div>ForcedVRD</div>
                        </mat-checkbox>
                    </div>

                    <div></div>
                </div>
            </ks-actionbar>
        </mat-tab>
        <mat-tab label="{{ 'firmware.firmware_topic' | translate }}">
            <div class="list-container full flex-column">
                <div class="full main-header flex-row gap-20">
                    <mat-icon class="kemppi-symbols">O</mat-icon>
                    <div class="flex-column">
                        <h2>{{ 'firmware.firmware_topic' | translate }}</h2>
                        <div>{{ 'firmware.firmware_description' | translate }}</div>
                    </div>
                </div>
                <h2 class="mat-subhead">{{ 'firmware.firmware_topic' | translate }}</h2>
                <div class="list-header-row flex-row">
                    <div style="width:40px"></div>
                    <div style="width:200px">
                        <small>{{ 'common.product' | translate }} / {{ 'common.version' | translate }}</small>
                    </div>
                    <div class="flex-grow">
                        <small>{{ 'common.description' | translate }}</small>
                    </div>
                </div>
                <div>
                    <mat-progress-spinner matSuffix *ngIf="loadingFirmwares" [mode]="'indeterminate'"
                        [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                </div>
                <div *ngIf="!loadingFirmwares && firmwares" class="full flex-column">
                    <ng-container *ngFor="let firmware of firmwares">
                        <div *ngIf="firmware" class="list-row-wrapper flex-column"
                            (click)="showFirmwares[firmware.id] = !showFirmwares[firmware.id]">
                            <div class="list-row flex-row align-center">
                                <div style="width:40px">
                                    <mat-icon class="kemppi-symbols" *ngIf="!showFirmwares[firmware.id]">÷</mat-icon>
                                    <mat-icon class="kemppi-symbols" *ngIf="showFirmwares[firmware.id]">ù</mat-icon>
                                </div>
                                <div style="width:200px">
                                    {{firmware.data.name}}
                                </div>
                                <div class="flex-grow">
                                    {{firmware.data.description}}
                                </div>
                                <div style="width:200px">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showFirmwares[firmware.id]" class="list-row-wrapper flex-column">
                            <div class="firmware-revisions flex-column">
                                <ng-container *ngFor="let revision of firmware.data.revisions">
                                    <div *ngIf="!revision.inactive" class="firmware-revision flex-row align-center">
                                        <div style="width:200px">
                                            {{revision.versionNumber}}
                                        </div>
                                        <div class="flex-grow text-with-line-breaks">
                                            {{revision.description}}
                                        </div>
                                        <div>
                                            <button mat-button color="accent"
                                                (click)="downloadRevisionWithTempUrl(firmware.id, revision.id)">
                                                <mat-icon>cloud_download</mat-icon>
                                                {{ 'common.download' | translate }}
                                            </button>
                                            <!--
                                            <button mat-button color="accent" (click)="downloadFirmware(firmware.id, revision.id)">
                                                <mat-icon>cloud_download</mat-icon>
                                            </button>
                                            -->
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>