<div class="full flex-column">
    <ks-toolbar [appInfo]="appInfo"
        [hasMenu]="stateService.state.hasMenu"
        [hasBack]="stateService.state.hasBack"
        [file]="stateService.state.file"
        (back)="back()">
    </ks-toolbar>

    <div *ngIf="stateService.state.loading" class="flex-row" style="margin-top: 200px;">
        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'"
                            style="margin: 0 auto;"
                            *ngIf="stateService.state.loading">
        </mat-progress-spinner>
    </div>
    <div *ngIf="!stateService.state.loading" id="ks-content-area">
        <router-outlet></router-outlet>
    </div>
</div>