import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { WarDatepickerComponent } from './components/war-datepicker/war-datepicker.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule,
        TranslateModule, // See app.module for loader
        RouterModule,
        MatDatepickerModule, MatMomentDateModule,
        FormsModule, ReactiveFormsModule
    ],
    declarations: [
        WarDatepickerComponent
    ],
    exports: [
        WarDatepickerComponent
    ]
})
export class WarCustomModule {
}
