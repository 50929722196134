import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

import { ZipReaderService } from '../../services/zip-reader.service';

import { SoftwaresListPageComponent } from './components/softwares-list-page/softwares-list-page.component';
import { SoftwarePageComponent } from './components/software-page/software-page.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule,
        MatProgressSpinnerModule, MatRadioModule, MatCheckboxModule, MatChipsModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule,
        FormsModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule
    ],
    declarations: [
        SoftwaresListPageComponent,
        SoftwarePageComponent
    ],
    providers: [
        ZipReaderService
    ],
    entryComponents: [
    ],
    exports: [
        SoftwaresListPageComponent,
        SoftwarePageComponent
    ]
})
export class SoftwaresModule { }
