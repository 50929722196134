import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

// import { IMobileDevice } from '../models/IMobileDevice';

@Injectable()
export class MobileDevicesService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.swhubBackendUrl;
        this.token = this.authService.accessToken;
    }

    getMobileDevice() {

    }

    getMobileDevices(searchParams = []) {
        console.log('getMobileDevices()');
        const sort = 'data.device.activated:desc';
        searchParams.push({ key: 'sort', value: sort });
        searchParams.push({ key: 'size', value: 1000 });
        return this.http.get(`${this.BACKENDURL}/mobile-devices?${this._parseSearchParamsToString(searchParams)}`, { headers: this._getHeaders() });
    }

    updateMobileDevice(mobileDevice) {
        console.log('updateMobileDevice()', mobileDevice);
        return this.http.put(`${this.BACKENDURL}/mobile-devices/update/${mobileDevice.id}`, JSON.stringify(mobileDevice), { headers: this._getHeaders() });
    }

    deleteMobileDevice(mobileDeviceIds) {
        console.log('deleteMobileDevice()', mobileDeviceIds);
        return this.http.post(`${this.BACKENDURL}/mobile-devices/delete`, JSON.stringify({ mobileDeviceIds }), { headers: this._getHeaders() });
    }

    getActivationToken() {
        console.log('getActivationToken()');
        return this.http.get(`${this.BACKENDURL}/mobile-activation/deviceactivationtoken`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
        });
    }

    _parseSearchParamsToString(searchParams) {
        const params = searchParams.map(param => `${param.key}=${param.value}`);
        return params.join('&');
    }

}
