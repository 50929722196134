import { get } from 'lodash';

import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MobileDevicesService } from 'src/app/services/mobile-devices.service';
import { IDevice } from 'src/app/models/IDevice';

@Component({
    selector: 'app-license-attach-dialog',
    templateUrl: 'license-attach-dialog.component.html',
    styles: [`
        .capitalize {
            text-transform: capitalize;
        }
    `]
})
export class LicenseAttachDialogComponent implements OnInit {

    public devices: Array<IDevice>;
    public loadingDevices: boolean;
    public detachLicenseAllowed: boolean;

    constructor(
        public dialogRef: MatDialogRef<LicenseAttachDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private mobileDevicesService: MobileDevicesService
    ) {
    }

    ngOnInit(): void {
        if (this.data.type === 'device') {
            this.searchDevices();
        }
        if (this.data.user && get(this.data.user, ['permissions', 'actions', 'licenses.write']) && (
            get(this.data.user, 'role') === 'koy_user' || get(this.data.user, 'role') === 'sys_admin')
        ) {
            this.detachLicenseAllowed = true;
        }
    }

    searchDevices() {
        if (this.data.user) {
            this.loadingDevices = true;
            const searchParams = [
                { key: 'company', value: this.data.user.company },
                { key: 'partnerId', value: this.data.user.partnerId }
            ];
            this.mobileDevicesService.getMobileDevices(searchParams).subscribe((devices: Array<IDevice>) => {
                console.log('devices', devices);
                this.devices = devices;
                this.loadingDevices = false;
            });
        }
    }

}
