<div class="main">
    <div class="list-container">
        <div class="full main-header flex-row gap-20">
            <mat-icon class="kemppi-symbols">s</mat-icon>
            <div class="flex-column">
                <h2>{{ 'menu.maintain_software' | translate }}</h2>
                <div>{{ 'maintain_software.maintain_software_description' | translate }}</div>
            </div>
        </div>
        
        <div class="full flex-column gap-20">
            <h2 class="mat-subhead">{{ 'common.search' | translate }}</h2>
            <div class="flex-row flex-wrap gap-20">
                <mat-form-field>
                    <mat-label>{{ 'common.name' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchName" (change)="search()">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'common.software_type' | translate }}</mat-label>
                    <mat-select [(value)]="searchType" (selectionChange)="search()">
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let softwareType of softwareTypes" [value]="softwareType">{{ softwareTypeNames[softwareType] || softwareType }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'common.product_family' | translate }}</mat-label>
                    <mat-select [(value)]="searchProductFamily" (selectionChange)="search()">
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let productFamily of productFamilies" [value]="productFamily">{{ productFamilyNames[productFamily] || productFamily }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'common.product_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchProductNumber" (change)="search()">
                </mat-form-field>
                <div>
                    <button mat-button color="warn"
                        (click)="resetSearchParams(); search()">{{ 'common.reset' | translate }}</button>
                </div>
            </div>
        </div>

        <div>
            <button mat-raised-button color="accent" (click)="createNewSoftware()">
                <mat-icon>add</mat-icon>
                {{ 'maintain_software.add_new_software' | translate }}
            </button>
            <div class="flex-grow"></div>
            <!--
            <button mat-raised-button color="" (click)="getFirmwaresByProductFamily()">
                <mat-icon>search</mat-icon>
                DEBUG: Get FIRMWARES (X5MIGWELDER)
            </button>
            -->
        </div>

        <h2 class="mat-subhead">{{ 'common.software' | translate }}</h2>
        <div class="list-header-row flex-row align-center">
                <div class="list-column-icon hide-in-sm">
                </div>
                <div class="list-column-name">
                    <small>{{ 'common.name' | translate }}</small>
                </div>
                <div class="list-column-type">
                    <small>{{ 'common.type' | translate }}</small>
                </div>
                <div class="list-column-product-family hide-in-sm">
                    <small>{{ 'common.product_family' | translate }}</small>
                </div>
                <div class="list-column-product-number hide-in-sm">
                    <small>{{ 'common.compatibility' | translate }}</small>
                </div>
                <div class="list-column-product-number hide-in-sm">
                    <small>{{ 'common.product_number' | translate }}</small>
                </div>
                <div class="list-column-product-name hide-in-sm">
                    <small>{{ 'common.product_name' | translate }}</small>
                </div>
                <div class="list-column-swcode hide-in-sm">
                    <small>swcode</small>
                </div>
                <div class="list-column-revisions hide-in-sm">
                    <small>{{ 'common.revisions' | translate }}</small>
                </div>
                <div class="list-column-latest hide-in-sm">
                    <small>{{ 'common.latest' | translate }} v.</small>
                </div>
                <div class="list-column-modified hide-in-sm">
                    <small>{{ 'common.modified' | translate }}</small>
                </div>
        </div>
        <div class="flex-column">
            <ng-container *ngFor="let software of softwares">
                <div *ngIf="software && software.data" class="list-row flex-row align-center" (click)="openSoftware(software.id)">
                    <div class="select-checkbox list-column-icon hide-in-sm">
                        <mat-icon *ngIf="software.data.private">lock</mat-icon>
                    </div>
                    <div  class="list-column-name">
                        {{software.data.name}}
                    </div>
                    <div class="list-column-type">
                        <small>{{softwareTypeNames[software.data.softwareType] || software.data.softwareType}}</small>
                    </div>
                    <div class="list-column-product-family hide-in-sm">
                        <small>{{productFamilyNames[software.data.productFamily] || software.data.productFamily}}</small>
                    </div>
                    <div class="list-column-product-number hide-in-sm">
                        <small>{{software.data.compatibility?.join(', ')}}</small>
                    </div>
                    <div class="list-column-product-number hide-in-sm" [ngClass]="{warn: software.data.productNumber && duplicateProductNumbers.indexOf(software.data.productNumber) >= 0}">
                        <small>{{software.data.productNumber}}</small>
                    </div>
                    <div class="list-column-product-name hide-in-sm">
                        <small>{{software.data.productName}}</small>
                    </div>
                    <div class="list-column-swcode hide-in-sm">
                        <small>{{software.data.swCode}}</small>
                    </div>
                    <div class="list-column-revisions hide-in-sm">
                        {{software.data.revisions?.length || 0}}
                    </div>
                    <div class="list-column-latest hide-in-sm">
                        {{ _.find(software.data.revisions, 'latestOfficial' )?.versionNumber || ''}}
                    </div>
                    <div class="date list-column-modified hide-in-sm">
                        <small>{{software.version.modified?.timestamp | date:'dd.MM.yyyy'}}</small>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
    <div class="flex-grow"></div>
</ks-actionbar>
