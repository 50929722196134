import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

import { ILicense } from '../models/ILicense';
import { IOrder } from '../models/IOrder';

import * as _ from 'lodash';
import * as uuidv4 from 'uuid/v4';
import { IAttachRequest, IUpdateRequest, IDeleteRequest } from '../models/ISWLicenseApi';

@Injectable()
export class LicensesService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.swhubBackendUrl;
        this.token = this.authService.accessToken;
    }

    getLicenses(searchParams = []) {
        console.log('getLicenses()');
        const sort = 'orderDate:desc';
        searchParams.push({ key: 'sort', value: sort });
        searchParams.push({ key: 'size', value: 1000 });
        return this.http.get(`${this.BACKENDURL}/licenses?${this._parseSearchParamsToString(searchParams)}`, { headers: this._getHeaders() });
    }

    getLicensesGrouped(searchParams = []) {
       // console.log('getLicensesGrouped()');
        const sort = 'orderDate:desc';
        searchParams.push({ key: 'sort', value: sort });
        return this.http.get(`${this.BACKENDURL}/licenses/grouped?${this._parseSearchParamsToString(searchParams)}`, { headers: this._getHeaders() });
    }

    getLicensesAll() {
        console.log('getLicensesAll()');
        const sort = 'orderDate:desc';
        return this.http.get(`${this.BACKENDURL}/licenses/all?sort=${sort}&size=1000`, { headers: this._getHeaders() });
    }

    attachLicensesTo(items: Array<{ id: string }>, type: IAttachRequest['type'], value: string, secureId: string = null) {
        const body: IAttachRequest = {
            type,
            value,
            secureId,
            licenseIds: _.map(items, 'id')
            // DEBUG
            // bundleIds: [ 'c8adb1e2-6008-47b6-8aa9-6924adc3c27d', '296aa52c-d19c-478b-bb57-84794f911ff6' ]
        };
        return this.http.post(`${this.BACKENDURL}/licenses/attach`, body, { headers: this._getHeaders() });
    }

    updateLicenses(items: Array<ILicense>) {
        const body: IUpdateRequest = items;
        return this.http.put(`${this.BACKENDURL}/licenses`, body, { headers: this._getHeaders() });
    }

    deleteLicenses(items: Array<{ id: string }>) {
        const body: IDeleteRequest = {
            licenseIds: _.map(items, 'id')
        };
        return this.http.post(`${this.BACKENDURL}/licenses/delete`, body, { headers: this._getHeaders() });
    }

    createLicensesFromOrder(order: IOrder) {
        return this.http.post(`${this.BACKENDURL}/licenses/order`, [order], { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
        });
    }

    _parseSearchParamsToString(searchParams) {
        const params = searchParams.map(param => `${param.key}=${param.value}`);
        return params.join('&');
    }

    // getLicenses() {
    //     return this.getLicensesFromStorage;
    // }

    // set setLicensesToStorage(licenses: Array<License>) {
    //     localStorage.setItem('sw-demo-licenses', JSON.stringify(licenses));
    // }

    // get getLicensesFromStorage(): Array<License> {
    //     try {
    //         const licenses = localStorage.getItem('sw-demo-licenses');
    //         if (licenses) {
    //             return JSON.parse(licenses) as Array<License>;
    //         } else {
    //             return [] as Array<License>;
    //         }
    //     } catch (e) {
    //         return [] as Array<License>;
    //     }
    // }

    // createLicensesFromOrder(order: Order) {
    //     const licenses: Array<License> = [];

    //     const userStamp = {
    //         timestamp: new Date().toISOString(),
    //         user: {
    //             id: 'demo',
    //             name: 'SW-license-demo'
    //         }
    //     };

    //     // Parse order to licenses
    //     for (const ol of order.orderLines) {
    //         for (let index = 0; index < ol.quantity; index++) {
    //             const license: License = {
    //                 id: uuidv4(),
    //                 company: order.company,
    //                 partnerId: order.partnerId,
    //                 partnerName: order.partnerName,
    //                 orderNumber: order.orderNumber,
    //                 referenceSource: order.source,
    //                 referenceNumber: order.reference,
    //                 productNumber: ol.partNumber,
    //                 productName: ol.description,
    //                 attachments: {
    //                     partner: userStamp
    //                 },
    //                 schema: {
    //                     type: 'software-license',
    //                     version: '0.1'
    //                 },
    //                 version: {
    //                     created: userStamp,
    //                     modified: userStamp
    //                 }
    //             };
    //             licenses.push(license);
    //         }
    //     }

    //     this.setLicensesToStorage = licenses.concat(this.getLicensesFromStorage);
    //     return licenses;
    // }

    // attachLicensesTo(items: Array<{ id: string }>, type: string, value: string) {
    //     const licenses = this.getLicenses();

    //     const userStamp = {
    //         timestamp: new Date().toISOString(),
    //         user: {
    //             id: 'demo',
    //             name: 'SW-license-demo'
    //         }
    //     };

    //     for (const item of items) {
    //         const index = _.findIndex(licenses, ['id', item.id]);
    //         console.log('index', index);
    //         if (index < 0) {
    //             continue;
    //         }
    //         if (type === 'serial') {
    //             licenses[index].serialNumber = value;
    //             licenses[index].attachments.serial = userStamp;
    //             licenses[index].version.modified = userStamp;
    //         } else if (type === 'device') {
    //             licenses[index].deviceId = value;
    //             licenses[index].attachments.device = userStamp;
    //             licenses[index].version.modified = userStamp;
    //         } else if (type === 'bundle') {
    //             licenses[index].bundleId = value;
    //             licenses[index].attachments.bundle = userStamp;
    //             licenses[index].version.modified = userStamp;
    //         }
    //     }

    //     this.setLicensesToStorage = licenses;
    // }

    // deleteLicenses(items: Array<{ id: string }>) {
    //     const licenses = this.getLicenses();

    //     for (const item of items) {
    //         const license = _.find(licenses, ['id', item.id]);
    //         _.remove(licenses, license);
    //     }

    //     this.setLicensesToStorage = licenses;
    // }

}
