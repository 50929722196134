import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CallbackComponent } from './modules/base/components/callback/callback.component';
import { LandingPageComponent } from './modules/base/components/landing-page/landing-page.component';
import { DevicesPageComponent } from './modules/devices/components/devices-page/devices-page.component';
import { LicensesPageComponent } from './modules/licenses/components/licenses-page/licenses-page.component';
import { SoftwaresListPageComponent } from './modules/softwares/components/softwares-list-page/softwares-list-page.component';
import { SoftwarePageComponent } from './modules/softwares/components/software-page/software-page.component';
import { OrderPageComponent } from './modules/licenses/components/order-page/order-page.component';
import { LoginPageComponent } from './modules/base/components/login-page/login-page.component';
import { LoginSelectPageComponent } from './modules/base/components/login-select-page/login-select-page.component';

import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'mobile-devices',
        component: DevicesPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'software',
        component: LicensesPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'maintain-software',
        component: SoftwaresListPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'maintain-software/:id',
        component: SoftwarePageComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuard] // Discard changes checker
    },
    {
        path: 'order',
        component: OrderPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'testlogin',
        component: LoginPageComponent
    },
    {
        path: 'selectlogin',
        component: LoginSelectPageComponent
    },
    {
        path: '**', redirectTo: ''
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
