import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Company, Companies } from 'src/app/models/company';
import { IDevice } from 'src/app/models/IDevice';
import { ILicense } from 'src/app/models/ILicense';
import { Permissions, UserProfile } from 'src/app/models/user';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { MobileDevicesService } from 'src/app/services/mobile-devices.service';

import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';
import { ValueDialogComponent } from 'src/app/shared/dialogs/components/value-dialog/value-dialog.component';

@Component({
    selector: 'app-devices-page',
    templateUrl: './devices-page.component.html',
    styleUrls: ['./devices-page.component.scss']
})
export class DevicesPageComponent implements OnInit {

    /**
     * User profile
     */
    user: UserProfile;

    public devices: Array<IDevice>;

    searchCompany: string;
    searchPartnerId: string;
    searchName: string;
    searchParams: Array<any>;

    public activationToken?: string;

    public selectedDevice?: string;

    public loadingDevices: boolean;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    constructor(
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private authService: AuthService,
        public stateService: StateService,
        private mobileDevicesService: MobileDevicesService
    ) {
        this.companies = Companies;
        this.selectedDevice = null;
        this.loadingDevices = true;
        this.resetSearchParams();
    }

    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.file = '';

            this.user = this.authService.userProfile;
            this.permissions = this.user.permissions;
            // Set default search to own company and partnerId
            this.searchCompany = this.user.company;
            this.searchPartnerId = this.user.partnerId;
            this.search();
        });
    }

    search() {
        this.checkSearchParams();
        this.searchDevices();
    }

    checkSearchParams() {
        this.searchParams = [];
        if (this.searchCompany !== '') {
            this.searchParams.push({ key: 'company', value: this.searchCompany });
        }
        if (this.searchPartnerId !== '') {
            this.searchParams.push({ key: 'partnerId', value: this.searchPartnerId });
        }
        if (this.searchName !== '') {
            this.searchParams.push({ key: 'name', value: this.searchName });
        }
    }

    resetSearchParams() {
        this.searchCompany = this.user ? this.user.company : '';
        this.searchPartnerId = this.user ? this.user.partnerId : '';
        this.searchName = '';
        this.searchParams = [];
    }

    searchDevices() {
        this.loadingDevices = true;
        this.mobileDevicesService.getMobileDevices(this.searchParams).subscribe((devices: Array<IDevice>) => {
            this.devices = devices;
            this.loadingDevices = false;
        }, (error) => {
            const errorMessage = _.get(error, 'error.errorMessage');
            this.notifyError(`Failed to load devices: ${errorMessage}`);
            this.loadingDevices = false;
        });
    }

    getToken() {
        this.mobileDevicesService.getActivationToken().subscribe((token: string) => {
            // tslint:disable-next-line: no-string-literal
            this.activationToken = token['access_token'];
            console.log('activationToken', this.activationToken);
        });
    }

    openRenameDialog() {
        const type = 'devices.rename_device';
        const label = 'common.name';
        const data = { type, label, value: '' };
        const dialogConfig: MatDialogConfig = { data };
        const dialogRef = this.dialog.open(ValueDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                console.log('data', data);
                this.renameSelectedItems(data.value);
            }
        });
    }

    renameSelectedItems(name) {
        const deviceIndex = this.devices.findIndex(d => d.id === this.selectedDevice);
        const device = this.devices[deviceIndex];
        device.data.device.name = name;
        this.mobileDevicesService.updateMobileDevice(device).subscribe((response) => {
            this.selectedDevice = null;
            setTimeout(() => {
                this.search();
            }, 2000);
        });
    }

    openDeleteConfirmDialog() {
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.delete_confirmation',
                content: 'confirm_dialog.are_you_sure_you_want_to_delete'
            }
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                this.deleteSelectedItems();
            }
        });
    }

    deleteSelectedItems() {
        this.mobileDevicesService.deleteMobileDevice([this.selectedDevice]).subscribe((response) => {
            console.log('delete response', response);
            const deviceIndex = this.devices.findIndex(d => d.id === this.selectedDevice);
            this.devices[deviceIndex].deleted = true;
            this.selectedDevice = null;
            setTimeout(() => {
                this.search();
            }, 2000);
        });
    }

    notifyError(message, duration = 5000) {
        this.snackBar.open(message, 'X', {
            duration,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }
}
