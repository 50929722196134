import { Component, OnInit, Input, Output, forwardRef, ElementRef, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as JsBarcode from 'jsbarcode';
import * as qrcode from 'qrcode-generator';
import * as _ from 'lodash';

/**
 * ks-barcode
 *
 * Displays a barcode. The barcode can be edited by passing true to editable property.
 * When editable clicking text will show a text input component to change the value.
 * To show the barcode text without it being editable use options and pass "displayValue: true"
 *
 * Usage:
 * <ks-barcode
 *  [(ngModel)]="barcode"
 *  [options]="{
 *      height: 35,
 *      displayValue: true
 *  }"
 *  editable="true"
 * ></ks-barcode>
 */
@Component({
    selector: 'ks-barcode',
    templateUrl: './ks-barcode.component.html',
    styleUrls: ['./ks-barcode.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KsBarcodeComponent),
        multi: true
    }]
})
export class KsBarcodeComponent implements OnInit, ControlValueAccessor {
    @ViewChild('ksBarcode') el: ElementRef;
    @ViewChild('inputElement') inputElement;

    @Input() options;
    @Input() editable = false;
    @Input() readOnly: boolean;
    @Input() type: '1D' | '2D' = '1D';
    @Input() qrCodeSize: number = 1.0; // multiplier
    @Input() qrCodeMargin: number = undefined;
    @Output() onLoad: EventEmitter<any> = new EventEmitter<any>();

    /**
     * If true, editing will be disabled and text is displayed in gray color.
     */
    @Input() disabled: boolean = false;

    /**
     * full wiki: https://github.com/lindell/JsBarcode/wiki
     */
    defaultOptions = {
        height: 20,
        format: 'code128b',
        fontSize: 12,
        font: '\'Open Sans\', sans-serif',
        width: 2,
        displayValue: false,
        margin: 0
    };

    innerValue = null;
    displayValue = null;
    editing = false;
    empty: string = '[empty]';

    constructor(private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        console.log('barcode ngOnInit', this);
        if (this.options) {
            _.assign(this.defaultOptions, this.options);
        }
    }

    /**
     * Rerender the component again if the type changes
     */
    ngOnChanges(changes): void {
        if (changes.type) {
            setTimeout(() => this.refreshDisplayValue(), 0);
        }
    }


    /** ngModel support **/
    private onTouchedCallback: () => void = () => { };
    private onChangeCallback: (_: any) => void = () => { };

    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            // change value only if non-empty
            if (v && _.trim(v).length) {
                this.innerValue = v;
                setTimeout(() => this.refreshDisplayValue(), 0);
                this.onChangeCallback(v);
            }
        }
    }

    // This is called on init
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            setTimeout(() => this.refreshDisplayValue(), 0);
        }

    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    /**
     * Refresh the text shown when not in edit mode
     */
    refreshDisplayValue() {
        this.displayValue = this.innerValue;
        if (this.el && this.el.nativeElement) {
            this.cdRef.markForCheck();
            if (this.innerValue) {
                if (this.type === '1D') {
                    JsBarcode(this.el.nativeElement, this.innerValue, this.defaultOptions);
                } else if (this.type === '2D') {
                    const qr = qrcode(0, 'H');
                    qr.addData(this.innerValue);
                    qr.make();
                    const tag = qr.createSvgTag(2.0 * this.qrCodeSize, this.qrCodeMargin);
                    this.el.nativeElement.innerHTML = tag;
                }
                this.emitOnload();
            }
        }
    }

    // Emit on load event
    emitOnload() {
        setTimeout(() => this.onLoad.emit(), 0);
    }

    /**
     * Clicked text to edit it.
     */
    onClick() {
        // don't allow editing if disabled
        if (this.disabled) {
            return;
        }

        this.editing = true;
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
        }, 0);

    }

    /**
     * Focusing out of the edit mode (input or select).
     */
    onBlur() {
        this.onTouchedCallback();
        this.editing = false;
    }
}
