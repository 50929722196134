<div class="main">
    <div class="full flex-row gap-20 justify-center">
        <fieldset class="login-container">
            <legend>Test login</legend>
            <div class="flex-column">
                <div class="flex-row gap-20">
                    <mat-form-field>
                        <mat-label>SF user id</mat-label>
                        <input matInput [(ngModel)]="loginParams.id" required [disabled]="publicLogin">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="loginParams.name" required>
                    </mat-form-field>
                </div>
                <div class="flex-row gap-20">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="loginParams.email" required [disabled]="publicLogin">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Role</mat-label>
                        <mat-select [(value)]="loginParams.role" required [disabled]="publicLogin">
                            <mat-option value="koy_user">Kemppi Oy</mat-option>
                            <mat-option value="koy_readonly">Kemppi Oy (readonly)</mat-option>
                            <mat-option value="sub_user">Subsidiary</mat-option>
                            <mat-option value="sub_readonly">Subsidiary (readonly)</mat-option>
                            <mat-option value="cus_distributor">Distributor</mat-option>
                            <mat-option value="cus_dealer">Dealer</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex-row gap-20">
                    <mat-form-field>
                        <mat-label>Partner id</mat-label>
                        <input matInput [(ngModel)]="loginParams.partnerId" (change)="validateAndParseUserType()" required [disabled]="publicLogin">
                        <mat-hint class="weak">({{ loginParams.userType }})</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Company</mat-label>
                        <mat-select [(value)]="loginParams.company" (selectionChange)="validateAndParseUserType()" required [disabled]="publicLogin">
                            <mat-option *ngFor="let companyCode of companyCodes" [value]="companyCode">{{companyCode}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex-row gap-20">
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input matInput [(ngModel)]="loginParams.password" type="password" (keydown.enter)="login()" [disabled]="publicLogin">
                    </mat-form-field>
                    <div>
                        <button mat-raised-button color="accent" (click)="login()">
                            <mat-icon>lock</mat-icon>
                            LOGIN
                        </button>
                    </div>
                    <mat-progress-spinner matSuffix *ngIf="loading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                </div>
                <div class="flex-row warn">
                    {{ loginError }}
                </div>
            </div>
        </fieldset>
    </div>
</div>
<!-- ACTIONBAR BUTTONS -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
