import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { LocaleService } from './services/locale.service';
import { StateService } from './services/state.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'swhub';
    appInfo = {
        icon: 's',
        title: 'Software Hub'
    };

    constructor(
        private router: Router,
        private localeService: LocaleService,
        public stateService: StateService
    ) {

        // Clear loading state after navigation end
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.stateService.state.loading = false;
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        this.localeService.language = 'en';
    }

    back() {
        if (this.router.url.split('/')[1] === 'maintain-software') {
            this.router.navigate(['maintain-software']);
        }
    }

}
