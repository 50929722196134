import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import {KsToolbarComponent} from './components/ks-toolbar/ks-toolbar.component';
import {KsActionbarComponent} from './components/ks-actionbar/ks-actionbar.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
//        MaterialModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule
    ],
    declarations: [KsToolbarComponent, KsActionbarComponent],
    exports: [KsToolbarComponent, KsActionbarComponent]
})
export class KsNavigationModule {
}
