import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { KsBarcodeComponent } from './components/ks-barcode/ks-barcode.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatMenuModule,
        MatButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatGridListModule
    ],
    declarations: [
        KsBarcodeComponent

    ],
    entryComponents: [
    ],
    exports: [
        KsBarcodeComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
    ]
})
export class KsFormSetModule {
}
